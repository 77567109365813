import React, { createContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useFirebase } from '../firebase/firebase-provider';
import { useSettings } from './settings-provider';
import { useAuth } from './auth-provider';
import { INVITATION_TYPE, useInvitations } from './invitations-provider';

const HomeContext = createContext({});
export const useHome = () => React.useContext(HomeContext);

const COLLECTION_HOMES = 'homes';
const COLLECTION_MEMBERS = 'members';

// export const SUB_ROLES = {
//     PLANNER_WRITE: 'PLANNER_WRITE',
// }

export const HOME_ROLES = {
    HEAD_CHEF: {
        id: 'HEAD_CHEF',
        name: 'Head Chef',
        description: 'Has complete control over the home. Can manage members, modify the meal planner and shopping lists',
    },
    SOUS_CHEF: {
        id: 'SOUS_CHEF',
        name: 'Sous Chef',
        description: 'Can plan meals and make shopping lists',
    },
    COOK: {
        id: 'COOK',
        name: 'Cook',
        description: 'Can view the planner, suggest meals and offer to cook',
    },
};

HomeProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function HomeProvider(props) {
    const { privateSettings, setHomeId, getUsersById } = useSettings();
    const [home, setHome] = useState(null);
    const [homeDb, setHomeDb] = useState(undefined);
    const { currentUser } = useAuth();
    const { sendInvite, removeHomeInvitation } = useInvitations();
    const fb = useFirebase();

    let homeId;
    if (privateSettings) {
        homeId = privateSettings.homeId ? privateSettings.homeId : null;
    }
    const homeCollection = fb.firestore().collection(COLLECTION_HOMES);

    useEffect(() => {
        if (homeId) {
            setHomeDb(homeCollection.doc(homeId));
        } else if (homeId === null) {
            setHomeDb(null);
        } else {
            setHomeDb(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeId]);

    useEffect(() => {
        if (homeId) {
            setHomeDb(homeCollection.doc(homeId));
        } else if (homeId === null) {
            setHomeDb(null);
        } else {
            setHomeDb(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeId]);

    useEffect(() => {
        if (homeDb) {
            homeDb.onSnapshot((doc) => {
                if (doc.exists) {
                    const homeData = doc.data();
                    homeDb.collection(COLLECTION_MEMBERS)
                        .onSnapshot((querySnapshot) => {
                            const memberIds = querySnapshot.docs.map((m) => m.id);
                            if (!memberIds.includes(currentUser.uid)
                                || !querySnapshot.docs.find((m) => m.id === currentUser.uid).data().confirmed) {
                                setHomeId(null);
                                setHome({});
                            } else {
                                getUsersById(memberIds).then((homeMembers) => {
                                    setHome({
                                        ...homeData,
                                        id: doc.id,
                                        members: homeMembers.map((member, index) => {
                                            const memberData = querySnapshot.docs[index].data();
                                            return ({
                                                ...member,
                                                role: HOME_ROLES[memberData.roleId],
                                                confirmed: !!memberData.confirmed,
                                            });
                                        }),
                                    });
                                });
                            }
                        }, (error) => {
                            console.debug(error);
                            setHome({});
                        });
                } else {
                    setHome({});
                    setHomeId(null);
                }
            }, (error) => {
                console.debug(error);
                setHome({});
                // TODO: Let the user know something went wrong
            });
        } else if (homeDb === null) {
            setHome({});
        } else {
            setHome(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeDb]);

    function addHome() {
        return new Promise((resolve) => {
            homeCollection.add({ ownerId: currentUser.uid })
                .then((docRef) => {
                    homeCollection.doc(docRef.id).collection(COLLECTION_MEMBERS).doc(currentUser.uid)
                        .set({ id: currentUser.uid, roleId: HOME_ROLES.HEAD_CHEF.id, confirmed: true })
                        .then(() => {
                            setHomeId(docRef.id);
                            resolve();
                        });
                })
                .catch((err) => {
                    console.debug(err);
                    resolve();
                });
        });
    }

    function updateHome(homeChange) {
        if (homeDb) {
            homeDb.set(homeChange, { merge: true }).then(() => {});
        }
    }

    function removeHome() {
        homeDb.delete().then(() => {
            setHomeId(null);
        });
    }

    function addMember(member, memberRoleId) {
        if (homeDb) {
            homeDb.collection(COLLECTION_MEMBERS).doc(member.id).set({ id: member.id, roleId: memberRoleId })
                .then(() => {
                    sendInvite(member.id, { type: INVITATION_TYPE.HOME, senderId: currentUser.uid, homeId })
                        .then(() => {});
                });
        }
    }

    function acceptHomeInvitation(invite) {
        return new Promise((resolve, reject) => {
            homeCollection.doc(invite.homeId).collection(COLLECTION_MEMBERS).doc(currentUser.uid)
                .set({ confirmed: true }, { merge: true })
                .then(() => {
                    removeHomeInvitation(invite.id);
                    setHomeId(invite.homeId);
                    resolve();
                })
                .catch(reject);
        });
    }

    function rejectHomeInvitation(invite) {
        return new Promise((resolve, reject) => {
            homeCollection.doc(invite.homeId).collection(COLLECTION_MEMBERS).doc(currentUser.uid)
                .delete()
                .then(() => {
                    removeHomeInvitation(invite.id);
                    setHomeId(null);
                    resolve();
                })
                .catch(reject);
        });
    }

    function updateMemberRole(member, memberRoleId) {
        if (homeDb) {
            homeDb.collection(COLLECTION_MEMBERS).doc(member.id).set({ roleId: memberRoleId }, { merge: true })
                .then(() => {});
        }
    }

    function removeMember(memberId) {
        if (homeDb) {
            homeDb.collection(COLLECTION_MEMBERS).doc(memberId).delete()
                .then(() => {});
        }
    }

    return (
        <HomeContext.Provider value={{ home, homeDb, addHome, addMember, updateMemberRole, removeMember, updateHome, removeHome, acceptHomeInvitation, rejectHomeInvitation }}>
            { props.children }
        </HomeContext.Provider>
    );
}
