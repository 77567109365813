import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { HOME_ROLES } from '../../context-providers/home-provider';


const useStyles = makeStyles((theme) => ({
    role: {
        marginTop: '10px',
        padding: '10px',
        transition: 'all 0.5s',
    },
    roleSelected: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

RoleSelector.propTypes = {
    selected: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
};

export default function RoleSelector(props) {
    const classes = useStyles();
    return (
        <Box>
            { Object.keys(HOME_ROLES).map((roleId) => {
                const role = HOME_ROLES[roleId];
                return (
                    <Paper onClick={() => props.setSelected(roleId)} elevation={3} key={roleId} className={clsx(classes.role, { [classes.roleSelected]: props.selected === roleId })}>
                        <Typography variant="h6" align="center">
                            {role.name}
                        </Typography>
                        <Typography variant="body1" align="center">
                            {role.description}
                        </Typography>
                    </Paper>
                );
            })}

        </Box>
    );
}
