import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Prompt, useHistory, useLocation, useParams } from 'react-router';
import { Box, Fade, ListItemText } from '@material-ui/core';
import * as queryString from 'query-string';
import cloneDeep from 'lodash.clonedeep';
import DeleteIcon from '@material-ui/icons/Delete';
import deepEqual from 'deep-equal';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import AddIcon from '@material-ui/icons/Add';
import uuid from 'uuid';
import Typography from '@material-ui/core/Typography';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import TitleBar from '../nav/title-bar';
import { useShoppingListStore } from '../../context-providers/shopping-list-store-provider';
import IntentButton, { Intent } from '../input/intent-button';
import ROUTES from '../../routes/routes';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import ShoppingListItem from './shopping-list-item';
import usePreventReload from '../../hooks/use-prevent-reload';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        position: 'sticky',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            top: '54px',
        },
        [theme.breakpoints.up('sm')]: {
            top: '64px',
        },
    },
    button: {
        pointerEvents: 'all',
        margin: '5px 0',
    },
    noItems: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: '#999999',
    },
    list: {
        width: '100%',
    },
    addIcon: {
        padding: '9px',
        color: theme.palette.grey.A200,
    },
    addText: {
        color: theme.palette.grey.A200,
    },
    checkedItemsText: {
        color: theme.palette.grey.A200,
        padding: '10px 30px',
    },
    exit: {
        maxHeight: '60px',
        overflow: 'hidden',
    },
    exitActive: {
        maxHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'all 500ms',
        overflow: 'hidden',
    },
    enter: {
        maxHeight: 0,
        transition: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        overflow: 'hidden',
    },
    enterActive: {
        maxHeight: '60px',
        paddingTop: '8px',
        paddingBottom: '8px',
        transition: 'all 500ms',
        overflow: 'hidden',
    },
}));

export default function ShoppingList() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {
        getUserList,
        getHomeList,
        saveUserList,
        saveHomeList,
        removeUserList,
        removeHomeList,
    } = useShoppingListStore();
    const { shoppingListId } = useParams();
    const [carefullyCancel, setCarefullyCancel] = useState(false);

    const query = queryString.parse(location.search);
    const editMode = query.edit === 'true';
    const isHome = query.homeList === 'true';

    const getShoppingList = isHome ? getHomeList : getUserList;
    const saveShoppingList = isHome ? saveHomeList : saveUserList;
    const removeShoppingList = isHome ? removeHomeList : removeUserList;

    const shoppingList = getShoppingList(shoppingListId);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [pendingShoppingList, setPendingShoppingList] = useState(shoppingList);

    const changesMade = !deepEqual(shoppingList, pendingShoppingList);

    usePreventReload(changesMade);

    useEffect(() => {
        if (carefullyCancel) {
            setCarefullyCancel(false);
            history.push({
                pathname: location.pathname,
                search: `?homeList=${isHome ? 'true' : 'false'}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carefullyCancel]);

    useEffect(() => {
        if (!editMode && changesMade) {
            setPendingShoppingList(getShoppingList(shoppingListId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, shoppingList]);

    if (!shoppingList) {
        history.push(ROUTES.SHOPPING);
        return <div />;
    }

    function onItemsChange(items) {
        setPendingShoppingList({
            ...pendingShoppingList,
            items,
        });
    }

    function addItem() {
        setPendingShoppingList({
            ...pendingShoppingList,
            items: [
                ...pendingShoppingList.items,
                { id: uuid(), text: '' },
            ],
        });
    }

    function onRemoveItem(itemId) {
        const updateItems = cloneDeep(pendingShoppingList.items);
        const index = updateItems.findIndex((i) => i.id === itemId);
        updateItems.splice(index, 1);
        onItemsChange(updateItems);
    }

    function onShoppingListDelete() {
        removeShoppingList(shoppingList.shoppingListId);
        history.push(ROUTES.SHOPPING);
    }

    function onNameChange(name) {
        setPendingShoppingList({
            ...pendingShoppingList,
            name,
        });
    }

    function onItemUpdate(item) {
        const updateItems = cloneDeep(pendingShoppingList.items);
        const index = updateItems.findIndex((i) => i.id === item.id);
        updateItems[index] = item;
        setPendingShoppingList({
            ...pendingShoppingList,
            items: updateItems,
        });
    }

    function handleCheck(itemId) {
        const updateItems = cloneDeep(pendingShoppingList.items);
        const index = updateItems.findIndex((i) => i.id === itemId);
        updateItems[index].checked = !updateItems[index].checked;
        saveShoppingList({
            ...shoppingList,
            items: updateItems,
        }, false);
    }

    function savePendingShoppingList() {
        saveShoppingList(pendingShoppingList);
        history.push({
            pathname: location.pathname,
            search: `?homeList=${isHome ? 'true' : 'false'}`,
        });
    }

    function discardPendingShoppingList() {
        setCarefullyCancel(true);
    }

    const uncheckedItems = [];
    const checkedItems = [];

    if (pendingShoppingList.items && !!pendingShoppingList.items.length) {
        pendingShoppingList.items.forEach((item) => {
            if (item.checked) {
                checkedItems.push(item);
            } else {
                uncheckedItems.push(item);
            }
        });
    }

    return (
        <Box key={editMode ? 'shoppingListEdit' : 'shoppingListView'} className={classes.root}>
            <TitleBar
                title={pendingShoppingList.name}
                editMode={editMode}
                setTitle={onNameChange}
                onBack={() => history.push('/shopping')}
            />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    {editMode ? (
                        <Box className={classes.buttons}>
                            <IntentButton
                                className={classes.button}
                                disabled={!changesMade}
                                onClick={() => savePendingShoppingList()}
                                variant="contained"
                                intent={Intent.PRIMARY}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </IntentButton>
                            <IntentButton
                                className={classes.button}
                                onClick={() => discardPendingShoppingList()}
                                variant="contained"
                                intent={Intent.TERTIARY}
                                startIcon={<CancelIcon />}
                            >
                                Cancel
                            </IntentButton>
                        </Box>
                    ) : (
                        <Box className={classes.buttons}>
                            <IntentButton
                                className={classes.button}
                                onClick={() => history.push({ pathname: location.pathname, search: `?edit=true&homeList=${query.homeList}` })}
                                variant="contained"
                                intent={Intent.PRIMARY}
                                startIcon={<EditIcon fontSize="small" />}
                            >
                                Edit
                            </IntentButton>
                            <IntentButton
                                className={classes.button}
                                onClick={() => setShowDeleteDialog(true)}
                                variant="contained"
                                intent={Intent.ERROR}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </IntentButton>
                        </Box>
                    )}
                    <Box>
                        <List className={classes.list}>
                            <Divider />
                            <TransitionGroup>
                                {uncheckedItems.map((item, index) => (
                                    <CSSTransition
                                        key={item.id}
                                        timeout={500}
                                        classNames={{
                                            enter: classes.enter,
                                            enterActive: classes.enterActive,
                                            exit: classes.exit,
                                            exitActive: classes.exitActive,
                                        }}
                                    >
                                        <ShoppingListItem
                                            autoFocus
                                            editMode={editMode}
                                            key={item.id}
                                            item={item}
                                            setItem={onItemUpdate}
                                            removeItem={() => onRemoveItem(item.id)}
                                            handleCheck={() => handleCheck(item.id)}
                                            addItem={index === uncheckedItems.length - 1 ? addItem : null}
                                        />
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                            {editMode && (
                                <ListItem key="add-item" onClick={addItem}>
                                    <AddIcon className={classes.addIcon} />
                                    <ListItemText className={classes.addText} primary="Add Item" />
                                </ListItem>
                            )}
                            <Divider />
                            <Typography key="ticked-items-text" className={classes.checkedItemsText} variant="body1">
                                {`${checkedItems.length} Ticked item${checkedItems.length !== 1 ? 's' : ''}`}
                            </Typography>
                            <TransitionGroup>
                                {checkedItems.map((item) => (
                                    <CSSTransition
                                        key={item.id}
                                        timeout={500}
                                        classNames={{
                                            enter: classes.enter,
                                            enterActive: classes.enterActive,
                                            exit: classes.exit,
                                            exitActive: classes.exitActive,
                                        }}
                                    >
                                        <ShoppingListItem
                                            editMode={editMode}
                                            key={item.id}
                                            item={item}
                                            setItem={onItemUpdate}
                                            removeItem={() => onRemoveItem(item.id)}
                                            handleCheck={() => handleCheck(item.id)}
                                        />
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                        </List>
                    </Box>
                    <ConfirmDeleteDialog
                        open={showDeleteDialog}
                        handleClose={() => setShowDeleteDialog(false)}
                        item={shoppingList}
                        onDelete={onShoppingListDelete}
                        idProp="shoppingListId"
                        itemType="Shopping List"
                    />
                    <Prompt
                        message={(loc, action) => (editMode && changesMade && ((action === 'POP' || carefullyCancel || location.pathname !== loc.pathname))
                            ? 'Are you sure you want to leave this page? You have unsaved changes'
                            : true)}
                    />
                    <Box id="bottom-shim" style={{ height: '90px' }} />
                </Box>
            </Fade>
        </Box>

    );
}
