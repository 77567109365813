import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import * as PropTypes from 'prop-types';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { processImageUpload } from '../../util/image-helper';
import IntentButton, { Intent } from './intent-button';


const ERROR = {
    NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
    FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE',
};

ImageUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
    imgExtension: PropTypes.arrayOf(PropTypes.string),
    onError: PropTypes.func,
    maxFileSize: PropTypes.number,
    className: PropTypes.string,
    buttonText: PropTypes.string,
};

ImageUploader.defaultProps = {
    imgExtension: ['.jpg', '.gif', '.png', '.gif', '.jfif'],
    onError: null,
    maxFileSize: 5242880,
    className: '',
    buttonText: 'Edit Photo',
};

export default function ImageUploader(props) {
    const inputEl = useRef(null);

    function hasExtension(fileName) {
        const pattern = `(${props.imgExtension.join('|').replace(/\./g, '\\.')})$`;
        return new RegExp(pattern, 'i').test(fileName);
    }

    function onUploadClick(e) {
        e.target.value = null;
    }

    /*
     Read a file and return a promise that when resolved gives the file itself and the data URL
   */
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            // Read, resize and compress the image.
            reader.onload = (upload) => processImageUpload(resolve, upload, file.name);
            reader.readAsDataURL(file);
        });
    }

    function onDropFile(e) {
        const { files } = e.target;

        const file = files[0];
        let fileError = {
            name: file.name,
        };
        // Check for file extension
        if (!hasExtension(file.name)) {
            fileError = Object.assign(fileError, {
                type: ERROR.NOT_SUPPORTED_EXTENSION,
            });
            props.onError(fileError);
        } else if (file.size > props.maxFileSize) {
            fileError = Object.assign(fileError, {
                type: ERROR.FILESIZE_TOO_LARGE,
            });
            props.onError(fileError);
        } else {
            readFile(file).then(props.onChange);
        }
    }

    return (
        <Box className={props.className}>
            <input
                type="file"
                ref={inputEl}
                multiple={false}
                onChange={onDropFile}
                onClick={onUploadClick}
                accept="image/*"
                style={{ display: 'none' }}
            />
            <IntentButton
                variant="contained"
                intent={Intent.SECONDARY}
                onClick={() => inputEl.current.click()}
                startIcon={<PhotoCameraIcon />}
            >
                {props.buttonText}
            </IntentButton>
        </Box>
    );
}
