import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import makeStyles from '@material-ui/styles/makeStyles';
import { HOME_ROLES, useHome } from '../../context-providers/home-provider';
import { useFriendships } from '../../context-providers/friendships-provider';
import RoleSelector from './role-selector';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    option: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
}));

HomeMemberDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    saveMember: PropTypes.func.isRequired,
    member: PropTypes.shape({
        role: PropTypes.shape({ id: PropTypes.string }),
        username: PropTypes.string,
        img: PropTypes.string,
    }),
};

HomeMemberDialog.defaultProps = {
    member: null,
};

export default function HomeMemberDialog(props) {
    const classes = useStyles();
    const { open, handleClose } = props;
    const { friends } = useFriendships();
    const { home } = useHome();
    const [member, setMember] = useState(props.member);
    const [roleId, setRoleId] = useState(props.member ? props.member.role.id : HOME_ROLES.COOK.id);
    const [availableFriends, setAvailableFriends] = useState([]);

    useEffect(() => {
        if (props.member) {
            setMember(props.member);
            setRoleId(props.member.role.id);
        } else {
            setMember(null);
            setRoleId(HOME_ROLES.COOK.id);
        }
    }, [props.member]);

    useEffect(() => {
        if (friends) {
            setAvailableFriends(friends.filter((f) => !home.members.find((m) => m.id === f.id)));
        }
    }, [friends, home]);

    function saveMember() {
        props.handleClose();
        props.saveMember(member, roleId);
        setMember(null);
    }

    return (
        <Dialog open={open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.member ? 'Update Home Member' : 'Add Home Member'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography className={classes.emptyText} variant="body1" align="center">
                        {member ? `Set a new role for ${member.username}` : 'Chose a friend to invite to your home and select a role'}
                    </Typography>
                </DialogContentText>
                <Box className={classes.root}>
                    { member ? (
                        <Avatar alt={member.username.toLocaleUpperCase()} src={member.img || '#'} />
                    ) : (
                        <Autocomplete
                            id="member-username"
                            options={availableFriends}
                            value={member}
                            onChange={(event, m) => {
                                setMember(m);
                            }}
                            getOptionLabel={(option) => option.username}
                            renderOption={(option) => (
                                <Box className={classes.option}>
                                    <Avatar alt={option.username.toLocaleUpperCase()} src={option.img || '#'} style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                                    {option.username}
                                </Box>
                            )}
                            style={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} fullWidth label="Username" variant="outlined" />}
                        />
                    )}
                    <RoleSelector selected={roleId} setSelected={setRoleId} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>
                    Cancel
                </Button>
                <Button disabled={!member} onClick={() => saveMember()} variant="contained" color="primary">
                    {member ? 'Update Member' : 'Add Member'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
