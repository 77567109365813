import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { Redirect, Switch } from 'react-router';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import TopBar from './components/nav/top-bar';
import Recipes from './components/recipe/recipes';
import Recipe from './components/recipe/recipe';
import Planner from './components/planner/planner';
import Meal from './components/planner/meal';
import ShoppingList from './components/shopping/shopping-list';
import Shopping from './components/shopping/shopping';
import Login from './views/login';
import Providers from './providers';
import VerifiedRoute from './routes/verified-route';
import SignUp from './views/signup';
import NoAuthRoute from './routes/no-auth-route';
import ROUTES from './routes/routes';
import Confirm from './views/confirm';
import UnverifiedRoute from './routes/unverified-route';
import BottomBar from './components/nav/bottom-bar';
import Home from './components/home/home';
import ScrollToTop from './components/nav/scroll-to-top';
import SetUp from './views/setup';
import SetUpRoute from './routes/setup-route';
import Initializer from './initializer';
import Friends from './components/friends/friends';
import Friend from './components/friends/friend';
import Settings from './views/settings';
import { useServiceWorker } from './context-providers/use-service-worker';
import 'animate.css';
import ConfirmNavDialog from './components/dialog/confirm-navigation-dialog';
import theme from './theme';

const useStyles = makeStyles(() => ({
    app: {
        minHeight: '100vh',
    },
    appContent: {
        minHeight: 'calc(100vh - 144px)',
    },
}));

function getUserConfirmation(message, callback) {
    const modal = document.getElementById('custom-prompt');

    const withCleanup = (answer) => {
        ReactDOM.unmountComponentAtNode(modal);
        callback(answer);
    };

    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <ConfirmNavDialog
                message={message}
                onCancel={() => withCleanup(false)}
                onConfirm={() => withCleanup(true)}
            />
        </ThemeProvider>,
        modal,
    );
}

function App() {
    const { isUpdateAvailable, updateAssets } = useServiceWorker();
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter getUserConfirmation={getUserConfirmation}>
                <ScrollToTop />
                <Providers>
                    <div className={classes.app}>
                        <TopBar />
                        <Initializer>
                            <Box className={classes.appContent}>
                                <Switch>
                                    <VerifiedRoute exact path={ROUTES.HOME} component={Home} />
                                    <VerifiedRoute exact path={ROUTES.RECIPES} component={Recipes} />
                                    <VerifiedRoute exact path={ROUTES.PLANNER} component={Planner} />
                                    <VerifiedRoute exact path={ROUTES.SHOPPING} component={Shopping} />
                                    <VerifiedRoute exact path={ROUTES.FRIENDS} component={Friends} />
                                    <VerifiedRoute exact path={ROUTES.SETTINGS} component={Settings} />
                                    <VerifiedRoute path="/shopping/:shoppingListId" component={ShoppingList} />
                                    <VerifiedRoute path="/recipes/:recipeId" component={Recipe} />
                                    <VerifiedRoute path="/planner/:mealId" component={Meal} />
                                    <VerifiedRoute path="/friends/:username/recipes/:recipeId" component={Recipe} />
                                    <VerifiedRoute path="/friends/:username" component={Friend} />
                                    <NoAuthRoute path={ROUTES.LOGIN} component={Login} />
                                    <NoAuthRoute path={ROUTES.SIGNUP} component={SignUp} />
                                    <UnverifiedRoute exact path={ROUTES.CONFIRM} component={Confirm} />
                                    <SetUpRoute path="/setup" component={SetUp} />
                                    <Redirect from="*" to={ROUTES.LOGIN} />
                                </Switch>
                            </Box>
                            <BottomBar />
                            {isUpdateAvailable && (
                                <Snackbar open>
                                    <Alert
                                        variant="filled"
                                        severity="info"
                                        action={(
                                            <Button color="secondary" size="small" onClick={updateAssets}>
                                                RELOAD
                                            </Button>
                                        )}
                                    >
                                        Cook&apos;d has been updated!
                                    </Alert>
                                </Snackbar>
                            )}
                        </Initializer>
                        <div id="custom-prompt" />
                    </div>
                </Providers>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
