import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Fade, InputBase } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import Paper from '@material-ui/core/Paper';
import { useRecipeManager } from '../../context-providers/recipe-manager-provider';
import TitleBar from '../nav/title-bar';
import RecipeGrid from '../recipe/recipe-grid';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import { useFriendships } from '../../context-providers/friendships-provider';
import ROUTES from '../../routes/routes';
import { useHome } from '../../context-providers/home-provider';

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'inline-flex',
        backgroundColor: theme.palette.background.paper,
    },
    searchContainer: {
        position: 'fixed',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        zIndex: 5,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#7f7f7f',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        width: '100%',
    },
}));

export default function Friend() {
    const classes = useStyles();
    const { friendships } = useFriendships();
    const { getAllFriendsRecipes } = useRecipeManager();
    const [recipeFilter, setRecipeFilter] = useState('');
    const [recipes, setRecipes] = useState(null);
    const history = useHistory();
    const { home } = useHome();

    const isHome = home && !!home.id;

    const { username } = useParams();
    const friend = friendships.find((f) => f.username === username);

    if (!friend) {
        history.push(ROUTES.FRIENDS);
    }

    useEffect(() => {
        getAllFriendsRecipes(friend.id).then(setRecipes); // Catch and handle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [friend.id]);

    function filterRecipes(recipe) {
        return recipe.name.toLocaleLowerCase().includes(recipeFilter)
            || recipe.labels.findIndex((label) => label.toLocaleLowerCase().includes(recipeFilter)) >= 0;
    }

    const filteredRecipes = recipes ? recipes.filter(filterRecipes) : null;

    return (
        <Box>
            <TitleBar title={username} onBack={() => history.push(ROUTES.FRIENDS)} />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    <Box className={classes.searchContainer}>
                        <Paper className={classes.search}>
                            <Box className={classes.searchIcon}>
                                <SearchIcon />
                            </Box>
                            <InputBase
                                placeholder="Search recipes…"
                                inputProps={{ 'aria-label': 'search recipes' }}
                                onChange={(e) => setRecipeFilter(e.target.value.toLocaleLowerCase())}
                            />
                        </Paper>
                    </Box>
                    <Box id="top-shim" style={{ height: '60px' }} />
                    <RecipeGrid
                        isFriends
                        isHome={isHome}
                        showOptions
                        recipes={filteredRecipes}
                        emptyText="This user doesn't have any recipes"
                    />
                    <Box id="bottom-shim" style={{ height: '90px' }} />
                </Box>
            </Fade>
        </Box>
    );
}
