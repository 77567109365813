import React, { useState } from 'react';
import { Box, Fade, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import Snackbar from '@material-ui/core/Snackbar';
import logo from '../resources/images/logo.svg';
import { useAuth } from '../context-providers/auth-provider';
import { DEFAULT_FADE_ANIMATION } from '../constants';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px auto',
    },
    padded: {
        margin: '10px auto 0 auto',
        textAlign: 'center',
    },
}));

export default function Confirm() {
    const classes = useStyles();
    const { sendEmailVerification, currentUser } = useAuth();
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    function resendConfirmation() {
        sendEmailVerification().then(() => setSnackBarOpen(true));
    }

    return (
        <Fade in timeout={DEFAULT_FADE_ANIMATION}>
            <Box className={classes.root}>
                <Image disableTransition src={logo} style={{ width: '100px', height: '120px', padding: 0 }} />
                <Typography gutterBottom variant="h6" className={classes.padded}>
                    {`A confirmation email has been sent to ${currentUser.email}`}
                </Typography>
                <Typography className={classes.padded}>
                    Please check your inbox for an email from noreply@cookdapp.com titled: <i>Verify your email for Cook&apos;d</i>
                    <br />
                    Click the link in the email the verify your account.
                </Typography>
                <Typography className={classes.padded}>
                    Happy Cooking!
                </Typography>
                <Typography variant="body2" className={classes.padded}>
                    Didn&apos;t receive an email?&nbsp;
                    <Link component="button" variant="body2" onClick={resendConfirmation}>Resend Confirmation</Link>
                </Typography>
                <Typography variant="body2">
                    Be sure to check your spam folder.
                </Typography>
                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={() => setSnackBarOpen(false)}>
                    <Alert onClose={() => setSnackBarOpen(false)} severity="success">
                        Verification Email Sent!
                    </Alert>
                </Snackbar>
            </Box>
        </Fade>
    );
}
