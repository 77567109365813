import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import * as PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    topBar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        padding: 0,
        zIndex: 5,
        [theme.breakpoints.down('sm')]: {
            // top: '64px',
            height: '54px',
        },
        [theme.breakpoints.up('sm')]: {
            // top: '64px',
            height: '64px',
        },
    },
    topBarShim: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '54px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '64px',
        },
    },
    titleText: {
        position: 'absolute',
        flexGrow: 1,
        fontWeight: 300,
        textAlign: 'center',
        lineHeight: 1.2,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
        },
        maxWidth: 'calc(100% - 85px)',
    },
    titleInputContainer: {
        width: 'calc(100% - 120px)',
        maxWidth: '600px',
        '&:before': {
            borderColor: `${theme.palette.primary.contrastText} !important`,
        },
    },
    titleInput: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
        },
    },
    backButton: {
        position: 'absolute',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            left: '5px',
        },
        [theme.breakpoints.up('sm')]: {
            left: '15px',
        },
    },
    editIcon: {
        color: theme.palette.primary.contrastText,
    },
}));

TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    setTitle: PropTypes.func,
    onBack: PropTypes.func,
    editMode: PropTypes.bool,
    static: PropTypes.bool,
};

TitleBar.defaultProps = {
    onBack: null,
    editMode: false,
    setTitle: null,
    static: true,
};
export default function TitleBar(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box>
            <AppBar position={props.static ? 'static' : 'fixed'} className={classes.topBar}>
                {props.onBack && !props.editMode && (
                    <IconButton className={classes.backButton} size="medium" aria-label="back" onClick={() => history.goBack()}>
                        <ArrowIcon fontSize="inherit" />
                    </IconButton>
                )}
                {props.editMode ? (
                    <Input
                        className={classes.titleInputContainer}
                        value={props.title}
                        onChange={(event) => props.setTitle(event.target.value)}
                        error={!props.title}
                        inputProps={{ className: classes.titleInput }}
                        fullWidth
                        startAdornment={(
                            <InputAdornment position="start" className={classes.editIcon}>
                                <EditIcon fontSize="small" />
                            </InputAdornment>
                        )}
                    />
                ) : (
                    <Typography className={classes.titleText}>
                        {props.title}
                    </Typography>
                )}
            </AppBar>
            <Box className={classes.topBarShim} />
        </Box>
    );
}
