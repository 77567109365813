import { AppBar, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core/index';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles/index';
import { useHistory, useLocation } from 'react-router';
import Image from 'material-ui-image';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { useAuth } from '../../context-providers/auth-provider';
import ROUTES from '../../routes/routes';
import cookdImg from '../../resources/images/cookd.svg';
import logo from '../../resources/images/logo_simple.svg';
import { useSettings } from '../../context-providers/settings-provider';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        background: theme.palette.primary.dark,
        maxHeight: '64px',
        transition: 'max-height .3s',
    },
    appName: {
        pointerEvents: 'none',
        width: '130px',
        margin: 'auto',
        transition: 'opacity .3s',
    },
    appLogo: {
        pointerEvents: 'none',
        transition: 'opacity .3s',
    },
    avatar: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    collapsed: {
        maxHeight: '0px',
    },
    hidden: {
        opacity: 0,
    },
    hoverAvatar: {
        pointerEvent: 'all',
        position: 'fixed',
        right: '10px',
        top: '2px',
        padding: 0,
    },
    toolbar: {
        pointerEvents: 'none',
    },
}));

export default function TopBar() {
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { logOut } = useAuth();
    const { publicSettings } = useSettings();

    const showAction = !location.pathname.startsWith(ROUTES.LOGIN)
        && !location.pathname.startsWith(ROUTES.SIGNUP);

    const barVisible = location.pathname.startsWith(ROUTES.SIGNUP)
                    || location.pathname.startsWith(ROUTES.LOGIN)
                    || location.pathname.startsWith(ROUTES.SETTINGS)
                    || location.pathname.startsWith(ROUTES.SETUP)
                    || location.pathname.startsWith(ROUTES.HOME);

    useEffect(() => {}, [publicSettings]);

    function doLogOut() {
        setOptionsAnchorEl(null);
        logOut().then(() => {
            history.push(ROUTES.LOGIN);
        });
    }

    function openSettings() {
        history.push(ROUTES.SETTINGS);
        setOptionsAnchorEl(null);
    }

    function renderOptionsButton() {
        if (publicSettings && publicSettings.username) {
            return (
                <Avatar alt={publicSettings.username.toLocaleUpperCase()} src={publicSettings.img || '#'} className={classes.avatar} />
            );
        }

        return (
            <MoreIcon fontSize="large" />
        );
    }

    function renderMenu() {
        return (
            <Menu
                getContentAnchorEl={null}
                anchorEl={optionsAnchorEl}
                open={Boolean(optionsAnchorEl)}
                onClose={() => setOptionsAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {publicSettings && publicSettings.username && (
                    <MenuItem onClick={openSettings}>
                        <ListItemIcon>
                            <SettingsIcon fontSize="default" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                )}
                <MenuItem onClick={doLogOut}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="default" />
                    </ListItemIcon>
                    Log Out
                </MenuItem>
            </Menu>
        );
    }

    return (
        <>
            <AppBar position="sticky" className={clsx(classes.mainBar, { [classes.collapsed]: !barVisible })}>
                <Toolbar classes={{
                    root: barVisible ? 'MuiToolbar-root' : `.MuiToolbar-root ${classes.toolbar}`,
                }}
                >
                    <Image className={clsx(classes.appLogo, { [classes.hidden]: !barVisible })} disableSpinner disableTransition src={logo} style={{ pointerEvents: 'none', background: 'none', width: '45px', height: '45px', padding: 0 }} />
                    <img className={clsx(classes.appName, { [classes.hidden]: !barVisible })} src={cookdImg} alt="Cook'd" />
                    {showAction && (
                        <div>
                            <IconButton
                                edge="start"
                                style={{ pointerEvents: 'all', padding: 0 }}
                                color="inherit"
                                aria-label="more"
                                onClick={(event) => setOptionsAnchorEl(event.currentTarget)}
                            >
                                {renderOptionsButton()}
                            </IconButton>
                            {renderMenu()}
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
}
