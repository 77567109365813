import React from 'react';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-gb';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { RecipeManagerProvider } from './context-providers/recipe-manager-provider';
import { MealPlannerProvider } from './context-providers/meal-planner-provider';
import { ShoppingListStoreProvider } from './context-providers/shopping-list-store-provider';
import { AuthProvider } from './context-providers/auth-provider';
import { DataStoreProvider } from './context-providers/datastore-provider';
import { SettingsProvider } from './context-providers/settings-provider';
import { FriendshipsProvider } from './context-providers/friendships-provider';
import { HomeProvider } from './context-providers/home-provider';
import { InvitationsProvider } from './context-providers/invitations-provider';
import CustomSnackbar from './components/feedback/custom-snackbar';

export default function Providers(props) {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider SnackbarComponent={CustomSnackbar} SnackbarProps={{ autoHideDuration: 5000 }}>
                <AuthProvider>
                    <DataStoreProvider>
                        <SettingsProvider>
                            <InvitationsProvider>
                                <HomeProvider>
                                    <RecipeManagerProvider>
                                        <MealPlannerProvider>
                                            <ShoppingListStoreProvider>
                                                <FriendshipsProvider>
                                                        {props.children}
                                                </FriendshipsProvider>
                                            </ShoppingListStoreProvider>
                                        </MealPlannerProvider>
                                    </RecipeManagerProvider>
                                </HomeProvider>
                            </InvitationsProvider>
                        </SettingsProvider>
                    </DataStoreProvider>
                </AuthProvider>
            </SnackbarProvider>
        </MuiPickersUtilsProvider>
    );
}
