import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import IntentButton, { Intent } from '../input/intent-button';

ConfirmDeleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    item: PropTypes.shape({ name: PropTypes.string }),
    onDelete: PropTypes.func.isRequired,
    idProp: PropTypes.string.isRequired,
    nameProp: PropTypes.string,
    itemType: PropTypes.string.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    buttonText: PropTypes.string,
};

ConfirmDeleteDialog.defaultProps = {
    item: null,
    nameProp: 'name',
    title: null,
    text: null,
    buttonText: null,
};

export default function ConfirmDeleteDialog(props) {
    if (!props.item) {
        return <div />;
    }

    return (
        <Dialog open={props.open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title || `Confirm Remove ${props.itemType}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.text || `Are you sure you want to remove ${props.item[props.nameProp]}?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()}>
                    Cancel
                </Button>
                <IntentButton
                    intent={Intent.ERROR}
                    onClick={() => {
                        props.onDelete(props.item[props.idProp]);
                        props.handleClose();
                    }}
                >
                    { props.buttonText || 'Delete' }
                </IntentButton>
            </DialogActions>
        </Dialog>
    );
}
