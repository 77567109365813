import React from 'react';
import * as firebase from 'firebase';
import firebaseConfig from './config';

const FirebaseContext = React.createContext(null);
export const useFirebase = () => React.useContext(FirebaseContext);

export function FirebaseProvider(props) {
    const app = firebase.initializeApp(firebaseConfig);
    app.firestore().settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    });
    app.firestore().enablePersistence()
        .catch((err) => {
            if (err.code === 'failed-precondition') {
                // Multiple tabs open, persistence can only be enabled
                // in one tab at a a time.
                console.warn('Persistence disabled due to multiple tabs being open, app will not function properly offline');
            } else if (err.code === 'unimplemented') {
                // The current browser does not support all of the
                // features required to enable persistence
                console.warn('Persistence disabled due to incompatible browser, app will not function properly offline');
            }
        });
    app.analytics();

    return (
        <FirebaseContext.Provider value={app}>
            {props.children}
        </FirebaseContext.Provider>
    );
}
