import React from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';

export const Intent = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',

};

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '0 5px',
        minWidth: '140px',
        maxWidth: '300px',
    },
    primaryButton: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    secondaryButton: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    tertiaryButton: {
        background: theme.palette.tertiary.main,
        color: theme.palette.tertiary.contrastText,
    },
    successButton: {
        background: theme.palette.success.dark,
        color: theme.palette.primary.contrastText,
    },
    warningButton: {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    errorButton: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },

}));

IntentButton.propTypes = {
    className: PropTypes.string,
    intent: PropTypes.string,
    startIcon: PropTypes.element,
};

IntentButton.defaultProps = {
    className: '',
    intent: '',
    startIcon: null,
};

export default function IntentButton(props) {
    const classes = useStyles();
    const color = props.intent === Intent.PRIMARY || props.intent === Intent.SECONDARY ? props.intent : 'inherit';

    const className = clsx(classes.button, props.className, {
        [classes.primaryButton]: props.intent === Intent.PRIMARY,
        [classes.secondaryButton]: props.intent === Intent.SECONDARY,
        [classes.tertiaryButton]: props.intent === Intent.TERTIARY,
        [classes.successButton]: props.intent === Intent.SUCCESS,
        [classes.warningButton]: props.intent === Intent.WARNING,
        [classes.errorButton]: props.intent === Intent.ERROR,
    });

    return (
        <Button color={color} {...props} className={className} startIcon={props.startIcon} />
    );
}
