import * as math from 'mathjs';

function improperFractionToMixedNumber(n, d) {
    const i = parseInt(n / d, 10);
    n -= i * d;
    return { i, n, d };
}

// Amount could be fraction
function formatAmount(amount, multiplier) {
    if (!amount) return null;
    const fraction = math.multiply(math.fraction(amount), multiplier);

    if (!fraction.d) {
        // Resolves to an integer
        return math.number(fraction);
    }
    // It's decimal
    let formattedAmount = '';
    const mixedNumber = improperFractionToMixedNumber(fraction.n, fraction.d);

    if (mixedNumber.i) {
        formattedAmount = `${mixedNumber.i} `;
    }
    if (mixedNumber.n && mixedNumber.d) {
        formattedAmount += `${mixedNumber.n}/${mixedNumber.d}`;
    }
    return formattedAmount;
}

export function parseAmount(amount) {
    if (!amount) return null;
    if (Number.isNaN(Number(amount))) {
        const [, i, n, d] = /^(\d+ )?(\d+)\/(\d+)$/.exec(amount);
        return math.add(i || 0, math.fraction(n, d));
    }
    return math.fraction(Number(amount));
}

export default function formatIngredient(ingredient, multiplier, nameFirst = false) {
    const ingredientParts = [];
    if (nameFirst) {
        ingredientParts.push(ingredient.name);
        ingredientParts.push('-');
    }
    if (ingredient.amount) {
        ingredientParts.push(formatAmount(ingredient.amount, multiplier));
    }
    if (ingredient.unit) {
        ingredientParts.push(ingredient.unit);
    }
    if (!nameFirst) {
        ingredientParts.push(ingredient.name);
    }

    return ingredientParts.join(' ');
}
