import React, { useRef, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import * as PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingRight: '64px',
        // maxHeight: '60px',
        // transition: 'all 500ms',
        overflow: 'hidden',
    },
    checkedItem: {
        textDecoration: 'line-through',
    },
    deleteButton: {
        position: 'absolute',
        right: '20px',
        padding: '5px 4px',
        color: theme.palette.error.main,
    },
    actions: {
        width: '64px',
        right: '6px;',
    },
    disabled: {
        color: theme.palette.text.primary,
    },
}));

ShoppingListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        checked: PropTypes.bool,
    }).isRequired,
    setItem: PropTypes.func.isRequired,
    handleCheck: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    addItem: PropTypes.func,
    autoFocus: PropTypes.bool,
    editMode: PropTypes.bool,
};

ShoppingListItem.defaultProps = {
    addItem: null,
    autoFocus: false,
    editMode: false,
};

export default function ShoppingListItem(props) {
    const classes = useStyles();
    const liRef = useRef(null);
    const [checked, setChecked] = useState(!!props.item.checked);

    function onCheck() {
        if (!props.editMode) {
            setChecked(!checked);
            props.handleCheck();
        }
    }

    function onTextChange(text) {
        props.setItem({
            ...props.item,
            text,
        });
    }

    function keyPressed(e) {
        if (e.charCode === 13 && props.addItem) {
            props.addItem();
            e.preventDefault();
        }
    }

    return (
        <ListItem ref={liRef} className={classes.listItem} onClick={() => onCheck()}>
            {!props.editMode && (
                <Checkbox
                    checked={checked || false}
                    onChange={() => onCheck()}
                    inputProps={{ 'aria-label': 'list-item-checkbox' }}
                />
            )}
            <InputBase
                autoFocus={props.autoFocus}
                className={clsx({ [classes.checkedItem]: checked })}
                classes={{ disabled: classes.disabled }}
                fullWidth
                value={props.item.text}
                onChange={(e) => onTextChange(e.target.value)}
                onKeyPress={keyPressed}
                disabled={!props.editMode}
            />
            {props.editMode && (
                <IconButton
                    className={classes.deleteButton}
                    onClick={() => props.removeItem()}
                    aria-label="remove-item"
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </ListItem>
    );
}
