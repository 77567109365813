import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'typeface-roboto';
import { FirebaseProvider } from './firebase/firebase-provider';
import { ServiceWorkerProvider } from './context-providers/use-service-worker';

ReactDOM.render(
    <ServiceWorkerProvider>
        <FirebaseProvider>
            <App />
        </FirebaseProvider>
    </ServiceWorkerProvider>,
    document.getElementById('root'),
);
