// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: 'AIzaSyAMk1pbc-aWa2_sN3gCqBLnqMovz8JedtM',
    authDomain: 'cookd-269722.firebaseapp.com',
    databaseURL: 'https://cookd-269722.firebaseio.com',
    projectId: 'cookd-269722',
    storageBucket: 'cookd-269722.appspot.com',
    messagingSenderId: '937183209654',
    appId: '1:937183209654:web:7e8dece71ca0cd6e9a9e17',
    measurementId: 'G-FQYE23L4YG',
};

export default firebaseConfig;
