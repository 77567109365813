import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LabelPill from './label-pill';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    labels: {
        marginLeft: '10px',
        height: '36px',
    },
    labelInput: {
        display: 'flex',
        width: '150px',
    },
    divider: {
        height: 28,
        margin: 4,
    },
    iconButton: {
        padding: '5px',
        marginLeft: '5px',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    emptyText: {
        color: theme.palette.grey.A200,
    },
}));

function recipeHasLabel(recipe, label) {
    return !recipe || !recipe.labels || recipe.labels.findIndex((l) => l.toLocaleLowerCase() === label.toLocaleLowerCase()) >= 0;
}

LabelList.propTypes = {
    recipe: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    setRecipe: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
};

LabelList.defaultProps = {
    editMode: false,
};
export default function LabelList(props) {
    const [newLabel, setNewLabel] = useState('');
    const classes = useStyles();

    useEffect(() => setNewLabel(''), [props.editMode]);

    function removeLabel(label) {
        const recipeToEdit = { ...props.recipe };
        const labelIndex = recipeToEdit.labels.findIndex((l) => l === label);
        recipeToEdit.labels.splice(labelIndex, 1);
        props.setRecipe(recipeToEdit);
    }

    function addNewLabel() {
        const label = newLabel.trim();
        if (!label || recipeHasLabel(props.recipe, label)) return;
        const recipeToEdit = { ...props.recipe };
        recipeToEdit.labels.push(label);
        props.setRecipe(recipeToEdit);
        setNewLabel('');
    }

    function handleKeyPress(e) {
        if (e.charCode === 13) {
            addNewLabel();
            e.preventDefault();
        }
    }

    return (
        <div className={classes.root}>
            {props.recipe && props.recipe.labels && props.recipe.labels.length
                ? props.recipe.labels.map((label) => (
                    <LabelPill
                        key={label}
                        label={label}
                        onDelete={props.editMode ? () => removeLabel(label) : null}
                    />
                ))
                : !props.editMode && <Typography className={classes.emptyText}>None</Typography>}
            {props.editMode
             && (
                 <Box className={classes.labels}>
                     <div className={classes.labelInput}>
                         <Tooltip
                             open={recipeHasLabel(props.recipe, newLabel.trim())}
                             disableFocusListener
                             disableHoverListener
                             disableTouchListener
                             title="Duplicate Label"
                         >
                             <Input
                                 className={classes.input}
                                 placeholder="Add Label"
                                 inputProps={{ 'aria-label': 'add label' }}
                                 value={newLabel}
                                 onChange={(event) => setNewLabel(event.target.value)}
                                 onKeyPress={handleKeyPress}
                             />
                         </Tooltip>
                         <IconButton
                             disabled={!newLabel.trim() || recipeHasLabel(props.recipe, newLabel.trim())}
                             onClick={addNewLabel}
                             className={classes.iconButton}
                             aria-label="directions"
                         >
                             <AddIcon />
                         </IconButton>
                     </div>
                 </Box>
             )}
        </div>
    );
}
