import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    field: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 10px 0 10px',
        padding: '10px',
        backgroundColor: theme.palette.grey['50'],
    },
    inlineField: {
        flexDirection: 'row',
    },
    fieldName: {
        fontWeight: '300',
        fontSize: '16px',
        color: theme.palette.grey.A400,
    },
}));

RecipeField.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    inline: PropTypes.bool,
};

RecipeField.defaultProps = {
    inline: false,
};

export default function RecipeField(props) {
    const classes = useStyles();

    return (
        <Paper elevation={2} className={clsx(classes.field, { [classes.inlineField]: props.inline })}>
            <Typography className={classes.fieldName} variant="body2">
                {props.name}
                {props.inline && ':'}
            </Typography>
            {props.children}
        </Paper>
    );
}
