import React, { useState } from 'react';
import * as firebase from 'firebase';
import { useFirebase } from '../firebase/firebase-provider';

const AuthContext = React.createContext(null);
export const useAuth = () => React.useContext(AuthContext);

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${window.location.origin}/login`,
    // This must be true.
    handleCodeInApp: true,
};

export function AuthProvider(props) {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [initialized, setInitialized] = useState(false);

    const fb = useFirebase();
    const auth = fb.auth();
    auth.onAuthStateChanged((user) => {
        updateCurrentUser(user);
    });

    function updateCurrentUser(user) {
        setCurrentUser(user);
    }

    function initializeApp() {
        if (initialized) {
            return;
        }
        const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            setCurrentUser(user);
            setInitialized(true);
            auth.onAuthStateChanged(setCurrentUser);
        });
    }

    function emailAndPasswordRegister(email, password) {
        return new Promise((resolve, reject) => {
            auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
                auth.createUserWithEmailAndPassword(email, password)
                    .then((newUser) => {
                        updateCurrentUser(newUser.user);
                        newUser.user.reload().then(() => {
                            sendEmailVerification().then(resolve).catch(reject);
                        });
                    }).catch(reject);
            });
        });
    }

    function sendEmailVerification() {
        return auth.currentUser.sendEmailVerification(actionCodeSettings);
    }

    function emailAndPasswordLogin(email, password) {
        return new Promise((resolve, reject) => {
            auth.signInWithEmailAndPassword(email, password).then((userCredentials) => {
                updateCurrentUser(userCredentials.user);
                resolve(userCredentials.user);
            }).catch(reject);
        });
    }

    function logOut() {
        return auth.signOut().then(() => {
            updateCurrentUser(undefined);
        });
    }

    function passwordReset(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function passwordUpdate(password) {
        return auth.currentUser.updatePassword(password);
    }

    function isAuthenticated() {
        return !!auth.currentUser;
    }

    function isVerified() {
        return isAuthenticated() && auth.currentUser.emailVerified;
    }

    return (
        <AuthContext.Provider
            value={{
                emailAndPasswordRegister,
                emailAndPasswordLogin,
                logOut,
                passwordReset,
                passwordUpdate,
                isAuthenticated,
                isVerified,
                sendEmailVerification,
                currentUser,
                initializeApp,
                initialized,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}
