import React, { createContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import * as PropTypes from 'prop-types';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useDataStore } from './datastore-provider';
import { useHome } from './home-provider';
import { useAuth } from './auth-provider';

const COLLECTION_NAME = 'recipes';
const RecipeManager = createContext({});
export const useRecipeManager = () => React.useContext(RecipeManager);

const RECIPE_DEFAULTS = {
    labels: [],
    serves: 4,
    ingredients: [],
    method: [],
};

RecipeManagerProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function RecipeManagerProvider(props) {
    const [userRecipes, setUserRecipes] = useState(null);
    const [homeRecipes, setHomeRecipes] = useState([]);
    const { currentUser } = useAuth();
    const { userDb, getUserStore } = useDataStore();
    const { home } = useHome();
    const snackbar = useSnackbar();

    useEffect(loadUserRecipes, [userDb]);
    useEffect(loadHomeRecipes, [home]);

    function loadUserRecipes() {
        if (userDb) {
            userDb.collection(COLLECTION_NAME)
                .onSnapshot((querySnapshot) => {
                    if (!querySnapshot.docs || !querySnapshot.docs.length) {
                        setUserRecipes([]);
                    } else {
                        setUserRecipes(querySnapshot.docs.map((doc) => loadRecipe(doc.data(), doc.id)));
                    }
                }, (error) => {
                    console.debug(error);
                    setUserRecipes(userRecipes || []);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        } else {
            setUserRecipes(null);
        }
    }

    function loadHomeRecipes() {
        if (home && home.id) {
            const homeRecipePromises = [];
            home.members.filter((m) => m.id !== currentUser.uid)
                .forEach((m) => homeRecipePromises.push(() => getAllFriendsRecipes(m.id)));
            homeRecipePromises.reduce(async (previousPromise, nextAsyncFunction) => {
                await previousPromise;
                const memberRecipes = await nextAsyncFunction();
                setHomeRecipes([...homeRecipes, ...memberRecipes]);
            }, Promise.resolve());
        }
    }

    function getAllFriendsRecipes(friendId) {
        const store = getUserStore(friendId);
        if (store) {
            return new Promise(((resolve) => store.collection(COLLECTION_NAME)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.docs || !querySnapshot.docs.length) {
                        resolve([]);
                    } else {
                        resolve(querySnapshot.docs.map((doc) => loadRecipe(doc.data(), doc.id, friendId)));
                    }
                })));
        }
        return Promise.reject(new Error('Failed to retrieve friends userRecipes'));
    }

    function getFriendsRecipe(friendId, recipeId) {
        const store = getUserStore(friendId);
        if (store) {
            return new Promise(((resolve) => store.collection(COLLECTION_NAME).doc(recipeId)
                .get()
                .then((doc) => {
                    if (!doc.exists) {
                        resolve(null);
                        snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                    } else {
                        resolve(loadRecipe(doc.data(), doc.id));
                    }
                })));
        }
        return Promise.reject(new Error('Failed to retrieve recipe'));
    }
    /**
     * Load a recipe
     * Do any upgrading required between versions of the data
     * @param recipe The recipe to upgrade
     * @param id  The recipe id
     * @param sharedFrom User that shared this recipe, null if owned by the logged in user
     */
    function loadRecipe(recipe, id, sharedFrom = null) {
        let loadedRecipe = { method: [], ...recipe, recipeId: id, sharedFrom };
        if (recipe.method && !Array.isArray(recipe.method)) {
            loadedRecipe = { ...loadedRecipe, method: [], notes: recipe.method };
        }
        return loadedRecipe;
    }

    /**
     * Add a single recipe
     * @param recipe Recipe data as an object
     * @return The id of the newly added recipe
     */
    function addRecipe(recipe) {
        return new Promise((resolve, reject) => {
            const newRecipe = { ...cloneDeep(RECIPE_DEFAULTS), ...recipe };
            if (userDb) {
                userDb.collection(COLLECTION_NAME).add(newRecipe)
                    .then((docRef) => {
                        snackbar.showMessage('Recipe Added', null, null, { severity: 'success' });
                        resolve(docRef.id);
                    })
                    .catch((error) => {
                        console.debug(error);
                        snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                        reject(error);
                    });
            }
        });
    }

    /**
     * Save a recipe
     * @param recipe The recipe to save
     */
    function saveRecipe(recipe) {
        if (userDb) {
            userDb.collection(COLLECTION_NAME).doc(recipe.recipeId).update(recipe)
                .then(() => snackbar.showMessage('Recipe Saved', null, null, { severity: 'success' }))
                .catch((error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        }
    }

    /**
     * Remove a single recipe
     * @param recipeId The id of the recipe to remove
     */
    function removeRecipe(recipeId) {
        if (userDb) {
            const undoRemove = () => {
                const deleted = userRecipes.find((r) => r.recipeId === recipeId);
                userDb.collection(COLLECTION_NAME).doc(recipeId)
                    .set(deleted)
                    .then(() => snackbar.showMessage('Action Undone', null, null, { severity: 'success' }));
            };
            userDb.collection(COLLECTION_NAME).doc(recipeId).delete()
                .then(() => snackbar.showMessage('Recipe Deleted', 'UNDO', undoRemove, { severity: 'success' }))

                .catch((error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        }
    }

    function getHomeRecipe(recipeId) { return getRecipe(recipeId, homeRecipes); }
    function getUserRecipe(recipeId) { return getRecipe(recipeId, userRecipes); }
    function getRecipe(recipeId, theRecipes) {
        if (!recipeId || !theRecipes) return null;
        const recipe = theRecipes.find((r) => r.recipeId === recipeId);
        return cloneDeep(recipe);
    }

    return (
        <RecipeManager.Provider value={{ userRecipes, homeRecipes, addRecipe, removeRecipe, getHomeRecipe, getUserRecipe, saveRecipe, getAllFriendsRecipes, getFriendsRecipe }}>
            { props.children }
        </RecipeManager.Provider>
    );
}
