import React from 'react';
import { Redirect, Route } from 'react-router';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../context-providers/auth-provider';
import ROUTES from './routes';
import { useSettings } from '../context-providers/settings-provider';

export default function VerifiedRoute(props) {
    const { isAuthenticated, isVerified, initialized } = useAuth();
    const { publicSettings } = useSettings();

    if (!initialized || !publicSettings) {
        return (<Backdrop open><CircularProgress color="inherit" /></Backdrop>);
    }

    if (!isAuthenticated()) {
        return <Redirect to={ROUTES.LOGIN} />;
    }

    if (!isVerified()) {
        return <Redirect to={ROUTES.CONFIRM} />;
    }

    if (!publicSettings || !publicSettings.username) {
        return <Redirect to={ROUTES.SETUP} />;
    }

    return <Route {...props} />;
}
