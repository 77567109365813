import React, { useEffect, useRef, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles/index';
import clsx from 'clsx';
import formatIngredient from '../../util/ingredient-helper';

IngredientListItem.propTypes = {
    ingredient: PropTypes.shape({ checked: PropTypes.bool }).isRequired,
    updateIngredient: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    multiplier: PropTypes.number,
    showCheckBoxes: PropTypes.bool,
    handleCheck: PropTypes.func,
};

IngredientListItem.defaultProps = {
    multiplier: 1,
    editMode: false,
    showCheckBoxes: false,
    handleCheck: null,
};

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingRight: '64px',
        maxHeight: '60px',
        transition: 'all 500ms',
        overflow: 'hidden',
    },
    checkedItem: {
        textDecoration: 'line-through',
    },
    checkedAnimate: {
        maxHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    editButton: {
        padding: '5px 4px',
        color: theme.palette.primary.main,
    },
    deleteButton: {
        padding: '5px 4px',
        color: theme.palette.error.main,
    },
    actions: {
        width: '64px',
        right: '6px;',
    },
}));

export default function IngredientListItem(props) {
    const canCheck = props.showCheckBoxes && !props.editMode;
    const classes = useStyles();
    const liRef = useRef(null);
    const [checked, setChecked] = useState(!!props.ingredient.checked);

    useEffect(() => {
        liRef.current.classList.remove(classes.checkedAnimate);
        setChecked(props.ingredient.checked);
    }, [props.ingredient.checked, liRef, classes.checkedAnimate]);

    function onCheck() {
        setChecked(!checked);
        if (liRef) {
            liRef.current.classList.add(classes.checkedAnimate);
        }
        setTimeout(() => {
            props.handleCheck();
        }, 500);
    }

    return (
        <ListItem ref={liRef} className={classes.listItem} onClick={canCheck ? () => onCheck() : null} divider>
            {canCheck
            && (
                <Checkbox
                    checked={checked || false}
                    onChange={() => onCheck()}
                    inputProps={{ 'aria-label': 'ingredient checkbox' }}
                />
            )}
            <ListItemText
                secondaryTypographyProps={{
                    className: classes.checkedItem,
                }}
                classes={{
                    primary: clsx('MuiListItemText-primary', { [classes.checkedItem]: !props.editMode && checked }),
                }}
                primary={formatIngredient(props.ingredient, props.multiplier)}
            />
            {props.editMode
            && (
                <ListItemSecondaryAction className={classes.actions}>
                    <IconButton
                        className={classes.editButton}
                        onClick={() => props.updateIngredient()}
                        aria-label="edit-ingredient"
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => props.removeIngredient()}
                        aria-label="remove-ingredient"
                    >
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}
