import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import IntentButton, { Intent } from '../input/intent-button';

ConfirmNavDialog.propTypes = {
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default function ConfirmNavDialog(props) {
    return (
        <Dialog open onClose={() => props.onCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onCancel()}>
                    Cancel
                </Button>
                <IntentButton
                    intent={Intent.PRIMARY}
                    onClick={() => props.onConfirm()}
                >
                    OK
                </IntentButton>
            </DialogActions>
        </Dialog>
    );
}