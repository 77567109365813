import React, { useState } from 'react';
import List from '@material-ui/core/List';
import { Box, Fab, Fade, Paper } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useShoppingListStore } from '../../context-providers/shopping-list-store-provider';
import TitleBar from '../nav/title-bar';
import { formatDateRange } from '../../util/date-helper';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import { useHome } from '../../context-providers/home-provider';
import ShoppingItem from './shopping-item';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(12),
        right: theme.spacing(4),
        zIndex: 2,
    },
    noListsText: {
        color: theme.palette.grey.A200,
        fontWeight: 'normal',
        textAlign: 'center',
    },
    exit: {
        maxHeight: '60px',
        overflow: 'hidden',
    },
    exitActive: {
        maxHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'all 500ms',
        overflow: 'hidden',
    },
    enter: {
        maxHeight: 0,
        transition: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        overflow: 'hidden',
    },
    enterActive: {
        maxHeight: '60px',
        paddingTop: '8px',
        paddingBottom: '8px',
        transition: 'all 500ms',
        overflow: 'hidden',
    },
}));

export default function Shopping() {
    const { userShoppingLists, homeShoppingLists, addUserList, addHomeList, removeUserList, removeHomeList } = useShoppingListStore();
    const [selectedShoppingList, setSelectedShoppingList] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const { home } = useHome();

    const isHome = home && !!home.id;
    const [tab, setTab] = useState(isHome ? 0 : 1); // 1 = Personal, 0 = Home
    const shoppingLists = !tab ? homeShoppingLists : userShoppingLists;
    const addShoppingList = !tab ? addHomeList : addUserList;
    const removeShoppingList = !tab ? removeHomeList : removeUserList;

    function newShoppingList() {
        addShoppingList({ name: `New List - ${formatDateRange(new Date(), new Date())}` }).then((newId) => {
            history.push({
                pathname: `/shopping/${newId}`,
                search: `?edit=true&homeList=${tab ? 'false' : 'true'}`,
            });
        });
    }

    function openShoppingList(id) {
        history.push({
            pathname: `/shopping/${id}`,
            search: `?homeList=${tab ? 'false' : 'true'}`,
        });
    }

    function renderShoppingLists(lists) {
        if (!lists) {
            // eslint-disable-next-line react/no-array-index-key
            return new Array(30).fill(0).map((_, index) => <Skeleton height={50} key={index} animation="wave" className={classes.skelly} />);
        }
        if (lists.length) {
            return (
                <TransitionGroup>
                    {lists.map((shoppingList) => (
                        <CSSTransition
                            key={shoppingList.shoppingListId}
                            timeout={500}
                            classNames={{
                                enter: classes.enter,
                                enterActive: classes.enterActive,
                                exit: classes.exit,
                                exitActive: classes.exitActive,
                            }}
                        >
                            <ShoppingItem
                                onClick={openShoppingList}
                                shoppingList={shoppingList}
                                onDelete={() => {
                                    setShowDeleteDialog(true);
                                    setSelectedShoppingList(shoppingList);
                                }}
                            />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            );
        }

        return (
            <Typography className={classes.noListsText} paragraph variant="h6">
                No shopping lists
            </Typography>
        );
    }


    return (
        <Box className={classes.root}>
            <TitleBar title="Shopping Lists" />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    { isHome && (
                        <Paper square>
                            <Tabs variant="fullWidth" value={tab} onChange={(event, value) => setTab(value)}>
                                <Tab label="Home Lists" />
                                <Tab label="My Lists" />
                            </Tabs>
                        </Paper>
                    )}
                    <List key={`${tab}-list`} aria-label="shopping-lists">
                        { renderShoppingLists(shoppingLists) }
                    </List>
                    <ConfirmDeleteDialog
                        open={showDeleteDialog}
                        handleClose={() => setShowDeleteDialog(false)}
                        item={selectedShoppingList}
                        onDelete={removeShoppingList}
                        idProp="shoppingListId"
                        itemType="Shopping List"
                    />
                    <Fab onClick={newShoppingList} color="primary" aria-label="add" className={classes.fab}>
                        <PlaylistAddIcon />
                    </Fab>
                    <Box id="bottom-shim" style={{ height: '90px' }} />
                </Box>
            </Fade>
        </Box>
    );
}
