import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import uuid from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactSortable } from 'react-sortablejs';
import IntentButton, { Intent } from '../input/intent-button';
import RecipeMethodStep from './recipe-method-step';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px 0',
    },
    newStepButton: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px auto 0 auto',
    },
    showAllSwitch: {
        marginLeft: '10px',
    },
    dragHandle: {
        position: 'absolute',
        left: '5px',
        color: theme.palette.grey.A200,
    },
}));

RecipeMethod.propTypes = {
    method: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        step: PropTypes.string,
    })),
    setMethod: PropTypes.func,
    editMode: PropTypes.bool,
};

RecipeMethod.defaultProps = {
    method: [],
    editMode: false,
    setMethod: () => {},
};
export default function RecipeMethod(props) {
    const [expanded, setExpanded] = useState('');
    const [expandAll, setExpandAll] = useState(false);
    const classes = useStyles();

    function handleExpansionChange(id, isExpanded) {
        setExpandAll(false);
        setExpanded(isExpanded ? id : '');
    }

    function addStep() {
        const newId = uuid();
        props.setMethod([
            ...props.method,
            { id: newId, step: '' },
        ]);
        setExpanded(newId);
    }

    function updateStep(step) {
        const newMethod = props.method.map((s) => {
            if (s.id === step.id) {
                return step;
            }
            return s;
        });
        props.setMethod(newMethod);
    }

    function deleteStep(stepId) {
        const newMethod = [...props.method];
        const index = newMethod.findIndex((step) => step.id === stepId);
        newMethod.splice(index, 1);
        props.setMethod(newMethod);
    }

    return (
        <Box className={classes.root}>
            <FormControlLabel
                control={(
                    <Switch
                        className={classes.showAllSwitch}
                        checked={expandAll}
                        onChange={() => setExpandAll(!expandAll)}
                        inputProps={{ 'aria-label': 'show all steps' }}
                        value="showAll"
                    />
                )}
                label="Show All"
            />
            {props.method.length
                ? (
                    <ReactSortable list={props.method} setList={props.setMethod} handle={`.${classes.dragHandle}`}>
                        {props.method.map((s, index) => (
                            <RecipeMethodStep
                                expanded={expandAll || expanded === s.id}
                                editMode={props.editMode}
                                key={s.id}
                                updateStep={updateStep}
                                stepId={s.id}
                                handleExpansionChange={handleExpansionChange}
                                deleteStep={deleteStep}
                                stepName={`Step ${index + 1}`}
                                stepValue={s.step}
                                handleClass={classes.dragHandle}
                            />
                        ))}
                    </ReactSortable>
                ) : (
                    <Typography className={classes.emptyText} variant="body1" align="center">
                        No Method
                    </Typography>
                )}
            {props.editMode
            && (
                <IntentButton
                    className={classes.newStepButton}
                    onClick={addStep}
                    intent={Intent.PRIMARY}
                    startIcon={<AddIcon />}
                    variant="contained"
                >
                    Add Step
                </IntentButton>
            )}
        </Box>
    );
}
