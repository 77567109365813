import React, { useState } from 'react';
import { TextField, Box, Link, CircularProgress, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import IsEmail from 'isemail';
import IntentButton, { Intent } from '../components/input/intent-button';
import ROUTES from '../routes/routes';
import logo from '../resources/images/logo.svg';
import { useAuth } from '../context-providers/auth-provider';
import PasswordField from '../components/input/password-field';
import { DEFAULT_FADE_ANIMATION } from '../constants';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px auto',
        width: '250px',
    },
    padded: {
        marginTop: '10px',
    },
}));

export default function Login() {
    const classes = useStyles();
    const { emailAndPasswordLogin } = useAuth();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasEditedPassword, setHasEditedPassword] = useState(false);

    function emailPasswordLogin() {
        if (isLoading) return;
        setIsLoading(true);
        emailAndPasswordLogin(email, password).then((userCredentials) => {
            if (userCredentials.user.emailVerified) {
                history.push(ROUTES.RECIPES);
            } else {
                history.push(ROUTES.CONFIRM);
            }
            setErrorMsg('');
        }).catch((error) => {
            if (error.code === 400) {
                setErrorMsg('You username and/or password are incorrect');
            } else {
                setErrorMsg(error.message);
            }
        }).finally(() => setIsLoading(false));
    }

    function emailValid() {
        return IsEmail.validate(email);
    }

    return (
        <Fade in timeout={DEFAULT_FADE_ANIMATION}>
            <Box className={classes.root}>
                <Image disableTransition src={logo} style={{ width: '100px', height: '120px', padding: 0 }} />
                <TextField
                    autoFocus
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(event) => setEmail(event.target.value.trim())}
                    error={!!email && !emailValid()}
                />
                <PasswordField
                    className={classes.padded}
                    id="password"
                    label="Password"
                    variant="outlined"
                    autoComplete="current-password"
                    fullWidth
                    value={password}
                    onChange={(event) => {
                        setHasEditedPassword(true);
                        setPassword(event.target.value.trim());
                    }}
                    error={hasEditedPassword && !password}
                />
                <IntentButton disabled={!emailValid() || !password} onClick={emailPasswordLogin} variant="contained" className={classes.padded} intent={Intent.PRIMARY}>
                    { isLoading ? <CircularProgress size={25} color="inherit" /> : 'Login'}
                </IntentButton>
                {errorMsg && <Alert className={classes.padded} severity="error">{errorMsg}</Alert>}
                <Typography variant="body2" className={classes.padded}>
                    Don&apos;t have an account?&nbsp;
                    <Link component="button" variant="body2" onClick={() => history.push(ROUTES.SIGNUP)}>Sign up</Link>
                </Typography>
            </Box>
        </Fade>
    );
}
