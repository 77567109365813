import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    imgPlaceholderContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.grey.A100,
    },
    imgText: {
        marginBottom: '35%',
        color: theme.palette.grey.A400,
    },
}));

export default function ImagePlaceholder() {
    const classes = useStyles();

    return (
        <Box className={classes.imgPlaceholderContainer}>
            <Typography className={classes.imgText}>
                No Photo
            </Typography>
        </Box>
    );
}
