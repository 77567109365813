import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import IntentButton, { Intent } from '../input/intent-button';

RemoveHomeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};


export default function RemoveHomeDialog(props) {
    return (
        <Dialog open={props.open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirm Remove Home</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete your home? All members will lose access!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()}>
                    Cancel
                </Button>
                <IntentButton
                    intent={Intent.ERROR}
                    onClick={() => {
                        props.onDelete();
                        props.handleClose();
                    }}
                >
                    Delete
                </IntentButton>
            </DialogActions>
        </Dialog>
    );
}
