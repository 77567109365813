export const DEFAULT_FADE_ANIMATION = 300;

export const SINGULAR_UNITS = [
    'cm',
    'mm',
    'tsp',
    'tbsp',
    'fl.oz',
    'g',
    'kg',
    'l',
    'in',
    'mg',
    'ml',
    'mm',
    'oz',
];

export const UNITS = [
    'bars',
    'bottles',
    'boxes',
    'bulbs',
    'bunches',
    'cans',
    'cubes',
    'cups',
    'dashes',
    'drops',
    'fillets',
    'fl.oz',
    'g',
    'gallons',
    'granules',
    'halves',
    'heads',
    'jars',
    'kg',
    'l',
    'lb',
    'loaves',
    'mg',
    'ml',
    'mm',
    'oz',
    'packages',
    'packets',
    'pieces',
    'pinches',
    'pints',
    'quarts',
    'slices',
    'sprigs',
    'tbsp',
    'tsp',
];
