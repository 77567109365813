import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { CircularProgress, ListItemText, Menu, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreIcon from '@material-ui/icons/MoreVert';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import { useFriendships } from '../../context-providers/friendships-provider';

const useStyles = makeStyles((theme) => ({
    loading: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px',
    },
    userName: {
        marginLeft: '10px',
    },
}));

export default function FriendsList() {
    const { friends, removeFriendship } = useFriendships();
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedFriend, setSelectedFriend] = useState(null);
    const classes = useStyles();
    const history = useHistory();

    if (!friends) {
        return <Box className={classes.loading}><CircularProgress /></Box>;
    }

    if (!friends.length) {
        return (
            <Typography className={classes.emptyText} variant="body1" align="center">
                You have no friends
            </Typography>
        );
    }

    function openFriend(username) {
        history.push(`/friends/${username}`);
    }

    function onFriendClicked(event, friend) {
        setOptionsAnchorEl(event.currentTarget);
        setSelectedFriend(friend);
        event.preventDefault();
        event.stopPropagation();
    }

    function confirmRemoveFriend(friendId) {
        removeFriendship(friendId);
        setOptionsAnchorEl(null);
    }

    return (
        <>
            <List aria-label="friends">
                {friends.map((friend) => (
                    <ListItem onClick={() => openFriend(friend.username)} key={friend.id}>
                        <Avatar alt={friend.username.toLocaleUpperCase()} src={friend.img || '#'} />
                        <ListItemText className={classes.userName}>
                            {friend.username}
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton
                                className={classes.reject}
                                onClick={(e) => onFriendClicked(e, friend)}
                                aria-label="reject-invite"
                            >
                                <MoreIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>

                ))}
            </List>
            <Menu
                getContentAnchorEl={null}
                anchorEl={optionsAnchorEl}
                open={!!optionsAnchorEl}
                onClose={() => setOptionsAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem
                    className={classes.deleteButton}
                    onClick={() => setShowDeleteDialog(true)}

                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="default" />
                    </ListItemIcon>
                    Remove Friend
                </MenuItem>
            </Menu>
            <ConfirmDeleteDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                item={selectedFriend}
                onDelete={confirmRemoveFriend}
                idProp="id"
                nameProp="username"
                itemType="Friend"
            />
        </>
    );
}
