import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import * as PropTypes from 'prop-types';
import React from 'react';

ShoppingItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    shoppingList: PropTypes.shape({
        shoppingListId: PropTypes.string,
        name: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default function ShoppingItem(props) {
    return (
        <ListItem
            onClick={() => props.onClick(props.shoppingList.shoppingListId)}
            divider
        >
            <ListItemText primary={props.shoppingList.name} secondary={`${props.shoppingList.items.length} items`} />
            <ListItemSecondaryAction>
                <IconButton
                    onClick={props.onDelete}
                    aria-label="delete-list"
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
