import React from 'react';
import { useAuth } from './context-providers/auth-provider';

export default function Initializer(props) {
    const { initialized, initializeApp } = useAuth();

    if (!initialized) {
        initializeApp();
    }

    return <>{props.children}</>;
}
