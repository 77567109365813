import React, { useState } from 'react';
import { Box, Fade } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Badge from '@material-ui/core/Badge';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import RecipeGrid from '../recipe/recipe-grid';
import TitleBar from '../nav/title-bar';
import { useMealPlanner } from '../../context-providers/meal-planner-provider';
import { getMealsForDateRange, getMealsForDay } from '../../util/meal-helper';
import DateRangePicker from '../input/date-range-picker';
import generateShoppingList from '../../util/shopping-list-helper';
import { useShoppingListStore } from '../../context-providers/shopping-list-store-provider';
import { formatDateRange } from '../../util/date-helper';
import IntentButton, { Intent } from '../input/intent-button';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import { useHome } from '../../context-providers/home-provider';

const useStyles = makeStyles(() => ({
    buttons: {
        display: 'flex',
    },
    recipesButton: {
        width: '45%',
        margin: '10px auto',
        maxWidth: '300px',
    },
    shoppingListButton: {
        width: '45%',
        margin: '10px auto',
        maxWidth: '300px',
    },
    summary: {
        textAlign: 'center',
        padding: '5px 20px',
    },
    datePicker: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px',
        backgroundColor: 'transparent',
    },
}));

export default function Planner() {
    const classes = useStyles();
    const { userMeals, homeMeals, removeUserMeal, removeHomeMeal } = useMealPlanner();
    const { addHomeList, addUserList } = useShoppingListStore();
    const { home } = useHome();
    const history = useHistory();
    const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = selectedRange;

    const isHome = home && !!home.id;
    const onDeleteMeal = isHome ? removeHomeMeal : removeUserMeal;
    const meals = isHome ? homeMeals : userMeals;
    const addShoppingList = isHome ? addHomeList : addUserList;
    const selectedMeals = meals ? getMealsForDateRange(meals, selectedRange[0], selectedRange[1]) : null;
    const numSelectedMeals = selectedMeals ? selectedMeals.length : 0;

    function selectDateRange(dateRange) {
        const [start, end] = dateRange;
        setSelectedRange([start.toDate(), end.toDate()]);
    }

    function newShoppingList() {
        const filteredMeals = getMealsForDateRange(meals, startDate, endDate);
        const newList = generateShoppingList(filteredMeals);
        newList.name = `Shopping List - ${formatSelectedDateRange()}`;
        addShoppingList(newList).then((newId) => {
            history.push(`/shopping/${newId}`);
        });
    }

    function formatSelectedDateRange() {
        return formatDateRange(startDate, endDate);
    }

    return (
        <Box>
            <TitleBar title="Meal Planner" />
            <br />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    <Typography className={classes.summary} variant="body2" gutterBottom>
                        Select a date range below to show the meals planned for that period. You can change dates or servings for individual meals.
                    </Typography>
                    <Paper elevation={3} className={classes.datePicker}>
                        <DateRangePicker
                            dateRange={selectedRange}
                            onChange={selectDateRange}
                            // disablePast // Maybe people want to be nostalgic about previous meals
                            disableToolbar
                            autoOk
                            variant="static"
                            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                const mealsOnDay = getMealsForDay(meals, day);
                                const isPast = !moment(day).isSameOrAfter(moment.now(), 'day');
                                return <Badge className={isPast && 'past'} color={isPast ? 'default' : 'secondary'} badgeContent={mealsOnDay.length ? mealsOnDay.length : undefined}>{dayComponent}</Badge>;
                            }}
                        />
                    </Paper>
                    <Box className={classes.buttons}>
                        <IntentButton
                            className={classes.recipesButton}
                            onClick={() => history.push('/recipes')}
                            variant="contained"
                            intent={Intent.PRIMARY}
                            startIcon={<AddIcon />}
                        >
                            Add Meals
                        </IntentButton>
                        <IntentButton
                            disabled={numSelectedMeals <= 0}
                            className={classes.shoppingListButton}
                            onClick={() => newShoppingList()}
                            variant="contained"
                            intent={Intent.SECONDARY}
                            startIcon={<PlaylistAddIcon />}
                        >
                            Shopping List
                        </IntentButton>
                    </Box>
                    <Typography className={classes.summary} variant="body2">
                        {`Showing ${numSelectedMeals} meal${numSelectedMeals === 1 ? '' : 's'} for `}
                        <strong>{formatSelectedDateRange()}</strong>
                    </Typography>
                    <RecipeGrid showOptions isMeals isHome={isHome} recipes={selectedMeals} onDelete={onDeleteMeal} />
                </Box>
            </Fade>
            <Box id="bottom-shim" style={{ height: '90px' }} />
        </Box>
    );
}
