import deepEqual from 'deep-equal';

export default function areRecipesEqual(a, b) {
    if (!a && !b) return true;
    if (!a || !b) return false;
    const recipeA = { ...a };
    const recipeB = { ...b };
    delete recipeA.method;
    delete recipeB.method;

    return deepEqual(recipeA, recipeB) && isMethodEqual(a.method, b.method);

}

function isMethodEqual(a, b) {
    if (!a && !b) return true;
    if (a.length !== b.length) return false;
    let equal = true;
    a.forEach((step, index) => {
        const stepA = { ...step };
        const stepB = b[index];
        delete stepA.selected;
        delete stepA.chosen;
        delete stepB.selected;
        delete stepB.chosen;
        if (!deepEqual(stepA, stepB)) {
            equal = false;
        }
    });
    return equal;
}