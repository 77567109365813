export function getRecipePhoto(img) {
    try {
        return require(`../resources/images/${img}`);
    } catch (err) {
        return `${img}`;
    }
}

export function processImageUpload(resolve, upload, filename) {
    const img = new Image();

    img.onload = () => {
        const width = 600;
        const scaleFactor = width / img.width;

        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);

        const dataURL = ctx.canvas.toDataURL('image/jpeg', 0.7);
        resolve(dataURL.replace(';base64', `;name=${filename};base64`));
    };
    img.src = upload.target.result;
}
