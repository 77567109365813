import React, { createContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useDataStore } from './datastore-provider';
import { useAuth } from './auth-provider';
import { useSettings } from './settings-provider';

const FriendshipsContext = createContext({});
export const useFriendships = () => React.useContext(FriendshipsContext);

const COLLECTION_NAME = 'friendships';

export const FRIENDSHIP_STATUS = {
    SENT: 'SENT',
    RECEIVED: 'RECEIVED',
    FRIEND: 'FRIEND',
    BLOCKED: 'BLOCKED',
};

const DEFAULT_FRIENDSHIP = { status: FRIENDSHIP_STATUS.RECEIVED };

FriendshipsProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function FriendshipsProvider(props) {
    const [friendships, setFriendships] = useState(null);
    const [friends, setFriends] = useState(null);
    const [receivedInvitations, setReceivedInvitations] = useState(null);
    const [sentInvitations, setSentInvitations] = useState(null);
    const { userDb, getUserStore } = useDataStore();
    const { currentUser } = useAuth();
    const { publicSettings, getUsersById } = useSettings();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (userDb) {
            userDb.collection(COLLECTION_NAME)
                .onSnapshot((querySnapshot) => {
                    if (!querySnapshot.docs || !querySnapshot.docs.length) {
                        setFriendships([]);
                    } else {
                        setFriendships(loadFriendships(querySnapshot.docs));
                    }
                }, (error) => {
                    console.debug(error);
                    setFriendships(friendships || []);
                    // TODO: Let the user know something went wrong
                });
        } else {
            setFriendships(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDb]);

    useEffect(() => {
        if (friendships) {
            const friendUsernames = [];
            const receivedUsernames = [];
            const sentUsernames = [];
            friendships.forEach((f) => {
                switch (f.status) {
                case FRIENDSHIP_STATUS.FRIEND:
                    friendUsernames.push(f.id);
                    break;
                case FRIENDSHIP_STATUS.RECEIVED:
                    receivedUsernames.push(f.id);
                    break;
                case FRIENDSHIP_STATUS.SENT:
                    sentUsernames.push(f.id);
                    break;
                default:
                }
            });

            getUsersById(friendUsernames).then(setFriends);
            getUsersById(receivedUsernames).then(setReceivedInvitations);
            getUsersById(sentUsernames).then(setSentInvitations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [friendships]);

    function loadFriendships(docs) {
        return docs.map((doc) => ({ ...DEFAULT_FRIENDSHIP, id: doc.id, ...doc.data() || [] }));
    }

    function blockUser(id) {
        setFriendshipStatus(id, FRIENDSHIP_STATUS.BLOCKED);
    }

    function acceptFriendship(id) {
        const store = getUserStore(id);
        if (store) {
            store.collection(COLLECTION_NAME).doc(currentUser.uid).update({ status: FRIENDSHIP_STATUS.FRIEND })
                .then(() => {
                    setFriendshipStatus(id, FRIENDSHIP_STATUS.FRIEND);
                });
        }
    }

    function setFriendshipStatus(id, status) {
        const friendship = friendships.find((f) => f.id === id);
        if (userDb && friendship) {
            userDb.collection(COLLECTION_NAME).doc(id).update({ username: friendship.username, status })
                .catch(console.debug); // TODO: show error message
        }
    }

    function addFriend({ id, username }) {
        if (id === currentUser.uid) return; // Can't add yourself silly
        const store = getUserStore(id);
        if (store) {
            store.collection(COLLECTION_NAME).doc(currentUser.uid).set({ status: FRIENDSHIP_STATUS.RECEIVED, username: publicSettings.username.trim().toLocaleLowerCase() })
                .then(() => {
                    const newFriend = { status: FRIENDSHIP_STATUS.SENT, username: username.trim().toLocaleLowerCase() };
                    userDb.collection(COLLECTION_NAME).doc(id).set(newFriend)
                        .then(() => {
                            snackbar.showMessage('Invite sent!'); // TODO: Email invite
                        })
                        .catch(console.debug); // TODO: show error message
                })
                .catch(console.debug); // TODO: show error message
        }
    }

    function removeFriendship(id) {
        const store = getUserStore(id);
        if (store) {
            store.collection(COLLECTION_NAME).doc(currentUser.uid).delete()
                .then(() => {
                    userDb.collection(COLLECTION_NAME).doc(id).delete()
                        .then(() => {
                            snackbar.showMessage('Friend Removed');
                        })
                        .catch(console.debug); // TODO: show error message
                })
                .catch(console.debug); // TODO: show error message
        }
    }

    function getFriend(id) {
        return friends.find((friend) => friend.id === id);
    }

    return (
        <FriendshipsContext.Provider value={{ friends, receivedInvitations, sentInvitations, friendships, removeFriendship, blockUser, acceptFriendship, addFriend, getFriend }}>
            { props.children }
        </FriendshipsContext.Provider>
    );
}
