import React, { createContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDataStore } from './datastore-provider';

const InvitationsContext = createContext({});
export const useInvitations = () => React.useContext(InvitationsContext);

const COLLECTION_NAME = 'invitations';

export const INVITATION_TYPE = {
    HOME: 'HOME',
};

InvitationsProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function InvitationsProvider(props) {
    const [homeInvitations, setHomeInvitations] = useState(null);
    const { userDb, getUserStore } = useDataStore();

    useEffect(() => {
        if (userDb) {
            userDb.collection(COLLECTION_NAME)
                .onSnapshot((querySnapshot) => {
                    loadInvitations(querySnapshot.docs || []);
                }, (error) => {
                    console.debug(error);
                    setHomeInvitations([]);
                    // TODO: Let the user know something went wrong
                });
        } else if (userDb === null) {
            setHomeInvitations([]);
        } else {
            setHomeInvitations(null);
        }
    }, [userDb]);

    function loadInvitations(docs) {
        const homeInvites = [];
        docs.forEach((doc) => {
            const invite = { ...doc.data(), id: doc.id };
            switch (invite.type) {
            case INVITATION_TYPE.HOME:
                homeInvites.push(invite);
                break;
            default:
            }
        });
        setHomeInvitations(homeInvites);
        return docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    }

    function removeHomeInvitation(id) {
        if (userDb) {
            userDb.collection(COLLECTION_NAME).doc(id).delete()
                .catch(console.debug); // TODO: show error message
        }
    }

    function sendInvite(userId, invite) {
        const store = getUserStore(userId);
        if (store) {
            return new Promise(((resolve) => store.collection(COLLECTION_NAME).add(invite)
                .then(resolve)));
        }
        return Promise.reject(new Error('Failed to send invite'));
    }

    return (
        <InvitationsContext.Provider value={{ homeInvitations, removeHomeInvitation, sendInvite }}>
            { props.children }
        </InvitationsContext.Provider>
    );
}
