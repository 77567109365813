import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { CircularProgress, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TickIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/Cancel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { useFriendships } from '../../context-providers/friendships-provider';

const useStyles = makeStyles((theme) => ({
    loading: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px',
    },
    accept: {
        color: theme.palette.success.main,
    },
    reject: {
        color: theme.palette.error.main,
    },
    userName: {
        marginLeft: '10px',
    },
}));

export default function ReceivedInvitationList() {
    const { removeFriendship, acceptFriendship, receivedInvitations } = useFriendships();
    const classes = useStyles();

    if (!receivedInvitations) {
        return (
            <Box className={classes.loading}>
                <CircularProgress />
            </Box>
        );
    }

    if (!receivedInvitations) {
        return <Box className={classes.loading}><CircularProgress /></Box>;
    }

    if (!receivedInvitations.length) {
        return (
            <Typography className={classes.emptyText} variant="body1" align="center">
                You have no invitations
            </Typography>
        );
    }

    return (
        <>
            <List aria-label="invitations">
                {receivedInvitations.map((invite) => (
                    <ListItem key={invite.id}>
                        <Avatar alt={invite.username.toLocaleUpperCase()} src={invite.img || '#'} />
                        <ListItemText className={classes.userName}>
                            {invite.username}
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {/* <IconButton */}
                            {/*    className={classes.reject} */}
                            {/*    onClick={() => blockUser(invite.id)} */}
                            {/*    aria-label="block-person" */}
                            {/* > */}
                            {/*    <CrossIcon /> */}
                            {/* </IconButton> */}
                            <IconButton
                                className={classes.reject}
                                onClick={() => removeFriendship(invite.id)}
                                aria-label="reject-invite"
                            >
                                <CrossIcon />
                            </IconButton>
                            <IconButton
                                className={classes.accept}
                                onClick={() => acceptFriendship(invite.id)}
                                aria-label="accept-invite"
                            >
                                <TickIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </>
    );
}
