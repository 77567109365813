import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles/index';
import { DatePicker, MuiPickersContext } from '@material-ui/pickers';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { formatDateRange } from '../../util/date-helper';

const useMyStyles = makeStyles((theme) => ({
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: 0,
        padding: 0,
        color: 'inherit',
        transition: 'none',
        borderRadius: 0,
    },
    dayDisabled: {
        color: 'rgba(0, 0, 0, 0.38)',
        pointerEvents: 'none',
    },
    middleDate: {
        borderRadius: 0,
        background: 'rgba(104, 149, 255, 0.5)',
        color: theme.palette.primary.dark,
        transition: 'background-color 0.2s',
    },
    startDate: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '50%',
        transition: 'background-color 0.2s',
        '&:hover': {
            background: theme.palette.primary.main,
        },
        '&:before': {
            position: 'absolute',
            top: 0,
            right: 0,
            content: "''",
            backgroundColor: theme.palette.primary.light,
            width: '50%',
            height: '100%',
            opacity: 0.5,
            zIndex: -1,
            transition: 'background-color 0.2s',
        },
    },
    endDate: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '50%',
        transition: 'background-color 0.2s',
        '&:hover': {
            background: theme.palette.primary.main,
        },
        '&:before': {
            position: 'absolute',
            top: 0,
            left: 0,
            content: "''",
            backgroundColor: theme.palette.primary.light,
            width: '50%',
            height: '100%',
            opacity: 0.5,
            zIndex: -1,
            transition: 'background-color 0.2s',
        },
    },
    singleDate: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '50%',
        opacity: 1,
        transition: 'background-color 0.2s',
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
}));

DateRangePicker.propTypes = {
    dateRange: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    renderDay: PropTypes.func,
};
DateRangePicker.defaultProps = {
    renderDay: null,
};
export default function DateRangePicker(props) {
    const [hover, setHover] = useState(undefined);
    const utils = useContext(MuiPickersContext);
    const classes = useMyStyles();

    const begin = moment(props.dateRange[0]);
    const end = moment(props.dateRange[1]);

    const min = Math.min(begin, end || hover);
    const max = Math.max(begin, end || hover);

    function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
        const isSelected = day >= min && day <= max;
        const isSingleDate = utils.isSameDay(day, min) && utils.isSameDay(day, max);
        const isStart = !isSingleDate && utils.isSameDay(day, min);
        const isEnd = !isSingleDate && utils.isSameDay(day, max);
        const isMiddle = isSelected && !isStart && !isEnd;
        const customDayComponent = React.cloneElement(dayComponent, {
            onClick: (e) => {
                e.stopPropagation();
                if (dayComponent.props.disabled) return;
                if (!utils.isSameDay(begin, end)) {
                    props.onChange([day, day]);
                } else if (day < begin) {
                    props.onChange([day, begin]);
                } else {
                    props.onChange([begin, day]);
                }
            },
            onMouseEnter: () => setHover(day),
            className: clsx(classes.day, {
                [classes.hidden]: dayComponent.props.hidden,
                [classes.current]: dayComponent.props.current,
                [classes.dayDisabled]: dayComponent.props.disabled,
                [classes.middleDate]: isMiddle,
                [classes.startDate]: isStart,
                [classes.endDate]: isEnd,
                [classes.singleDate]: isSingleDate,
            }),
        });
        return props.renderDay(day, selectedDate, dayInCurrentMonth, customDayComponent, isSelected);
    }

    return (
        <DatePicker
            {...props}
            value={begin}
            renderDay={renderDay}
            onChange={() => {}}
            labelFunc={() => formatDateRange(begin, end)}
        />
    );
}
