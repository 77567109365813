import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        common: {
            black: '#333333',
            white: '#fdfdfd',
        },
        background: {
            paper: '#fff',
            default: '#fafafa',
        },
        primary: {
            light: '#6895ff',
            main: '#0068f7',
            dark: '#003fc3',
            contrastText: '#fdfdfd',
        },
        secondary: {
            light: '#ff4081',
            main: '#f50057',
            dark: '#c51162',
            contrastText: '#fdfdfd',
        },
        tertiary: {
            main: '#4f4f4f',
            contrastText: '#fdfdfd',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fdfdfd',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
});

export default theme;
