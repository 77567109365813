import React, { useEffect, useState } from 'react';
import { useFirebase } from '../firebase/firebase-provider';
import { useAuth } from './auth-provider';

const DataStoreContext = React.createContext(null);
export const useDataStore = () => React.useContext(DataStoreContext);

export function DataStoreProvider(props) {
    const firebase = useFirebase();
    const { currentUser, isVerified } = useAuth();
    const [userDb, setUserDb] = useState(currentUser ? getUserStore(currentUser.uid) : undefined);

    useEffect(() => {
        if (currentUser) {
            setUserDb(getUserStore(currentUser.uid));
        } else if (currentUser === null) {
            setUserDb(undefined);
        } else {
            setUserDb(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    function getUserStore(uid) {
        return isVerified() ? firebase.firestore().collection('users').doc(uid) : null;
    }

    return (
        <DataStoreContext.Provider value={{ userDb, getUserStore }}>
            {props.children}
        </DataStoreContext.Provider>
    );
}
