import React, { createContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useHistory } from 'react-router';
import { useDataStore } from './datastore-provider';
import { useHome } from './home-provider';
import ROUTES from '../routes/routes';


const COLLECTION_NAME = 'meals';
const MealPlanner = createContext({});
export const useMealPlanner = () => React.useContext(MealPlanner);

MealPlannerProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function MealPlannerProvider(props) {
    const [userMeals, setUserMeals] = useState(null);
    const [homeMeals, setHomeMeals] = useState(null);
    const { userDb } = useDataStore();
    const { homeDb } = useHome();
    const snackbar = useSnackbar();
    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchMeals(userDb, setUserMeals), [userDb]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchMeals(homeDb, setHomeMeals), [homeDb]);

    function fetchMeals(db, setter) {
        if (db) {
            db.collection(COLLECTION_NAME)
                .onSnapshot((querySnapshot) => {
                    setter(querySnapshot.docs.map((doc) => loadMeal(doc.data(), doc.id)).filter(filterOldMeals));
                }, (error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        } else {
            setter(null);
        }
    }

    /**
     * Load a meal
     * Do any upgrading required between versions of the data
     * @param meal
     * @param id
     */
    function loadMeal(meal, id) {
        const upgraded = { originalServes: meal.serves, ...meal, mealId: id };
        upgraded.date = meal.date.toDate();

        if (meal.method && !Array.isArray(meal.method)) {
            upgraded.notes = upgraded.method;
            upgraded.method = [];
        }
        return upgraded;
    }

    function filterOldMeals(meal) {
        const minDate = new Date();
        minDate.setMonth(minDate.getMonth() - 3);
        return moment(meal.date).isSameOrAfter(minDate, 'day');
    }

    function addUserMeal(meal) { return addMeal(meal, userMeals, userDb); }
    function addHomeMeal(meal) { return addMeal(meal, homeMeals, homeDb); }
    function addMeal(meal, theMeals, db) {
        return new Promise(((resolve, reject) => {
            if (db) {
                db.collection(COLLECTION_NAME).add({ ...meal, originalServes: meal.serves })
                    .then((docRef) => {
                        resolve(docRef.id);
                        snackbar.showMessage('Meal Added', 'SEE PLAN', () => history.push(ROUTES.PLANNER), { severity: 'success' });
                    })
                    .catch((error) => {
                        console.debug(error);
                        snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                        reject(error);
                    });
            }
        }));
    }

    function saveUserMeal(meal) { saveMeal(meal, userMeals, userDb); }
    function saveHomeMeal(meal) { saveMeal(meal, homeMeals, homeDb); }
    function saveMeal(meal, theMeals, db) {
        if (db) {
            db.collection(COLLECTION_NAME).doc(meal.mealId).update(meal)
                .then(() => snackbar.showMessage('Meal Saved', null, null, { severity: 'success' }))
                .catch((error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        }
    }

    /**
     * Remove a single meal
     * @param mealId The id of the meal to remove
     */
    function removeUserMeal(mealId) { removeMeal(mealId, userMeals, userDb); }
    function removeHomeMeal(mealId) { removeMeal(mealId, homeMeals, homeDb); }
    function removeMeal(mealId, theMeals, db) {
        if (db) {
            const undoRemove = () => {
                const deleted = theMeals.find((m) => m.mealId === mealId);
                db.collection(COLLECTION_NAME).doc(mealId)
                    .set(deleted)
                    .then(() => snackbar.showMessage('Action Undone', null, null, { severity: 'success' }));
            };
            db.collection(COLLECTION_NAME).doc(mealId).delete()
                .then(() => snackbar.showMessage('Meal Deleted', 'UNDO', undoRemove, { severity: 'success' }))
                .catch((error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        }
    }

    function getUserMeal(mealId) { return getMeal(mealId, userMeals); }
    function getHomeMeal(mealId) { return getMeal(mealId, homeMeals); }
    function getMeal(mealId, theMeals) {
        if (!mealId || !theMeals) return null;
        const meal = theMeals.find((m) => m.mealId === mealId);
        return cloneDeep(meal);
    }

    return (
        <MealPlanner.Provider value={{
            userMeals,
            homeMeals,
            addUserMeal,
            addHomeMeal,
            saveUserMeal,
            saveHomeMeal,
            removeUserMeal,
            removeHomeMeal,
            getUserMeal,
            getHomeMeal,
        }}
        >
            { props.children }
        </MealPlanner.Provider>
    );
}
