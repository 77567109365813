import React, { useState } from 'react';
import { Box, Fade, Link, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordStrengthBar from 'react-password-strength-bar';
import IsEmail from 'isemail';
import IntentButton, { Intent } from '../components/input/intent-button';
import ROUTES from '../routes/routes';
import logo from '../resources/images/logo.svg';
import { useAuth } from '../context-providers/auth-provider';
import PasswordField from '../components/input/password-field';
import { DEFAULT_FADE_ANIMATION } from '../constants';

const PASSWORD_SCORE_THRESHOLD = 2; // Lowest allowed score


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px auto',
        width: '250px',
    },
    padded: {
        marginTop: '10px',
    },
    strengthBar: {
        width: '100%',
    },
    available: {
        color: theme.palette.success.main,
    },
    taken: {
        color: theme.palette.error.main,
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const { emailAndPasswordRegister } = useAuth();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordStrong, setPasswordStrong] = useState(false);


    function emailPasswordSignUp() {
        if (isLoading) return;
        setIsLoading(true);
        emailAndPasswordRegister(email, password).then(() => {
            history.push(ROUTES.CONFIRM);
        }).catch((error) => {
            setErrorMsg(error.message);
        }).finally(() => setIsLoading(false));
    }

    function passwordValid() {
        return passwordStrong;
    }

    function passwordsMatch() {
        return password === confirmPassword;
    }

    function detailsValid() {
        return email && password && passwordsMatch() && passwordStrong;
    }

    return (
        <Fade in timeout={DEFAULT_FADE_ANIMATION}>
            <Box className={classes.root}>
                <Image disableTransition src={logo} style={{ backgroundColor: 'none', width: '100px', height: '120px', padding: 0 }} />
                <TextField
                    autoFocus
                    className={classes.padded}
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    error={email && !IsEmail.validate(email)}
                />
                <PasswordField
                    className={classes.padded}
                    id="newPass"
                    type="password"
                    label="New Password"
                    variant="outlined"
                    autoComplete="new-password"
                    fullWidth
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    error={password && !passwordValid()}
                    helperText={passwordStrong || 'Password is too weak'}
                />
                <PasswordField
                    className={classes.padded}
                    id="confirmNewPass"
                    label="Confirm Password"
                    variant="outlined"
                    autoComplete="new-password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    error={confirmPassword && !passwordsMatch()}
                    helperText={passwordsMatch() || 'Passwords do not match'}
                />
                <PasswordStrengthBar className={classes.strengthBar} password={password} onChangeScore={(score) => setPasswordStrong(!password || score >= PASSWORD_SCORE_THRESHOLD)} />
                <IntentButton disabled={!detailsValid()} onClick={emailPasswordSignUp} variant="contained" className={classes.padded} intent={Intent.PRIMARY}>
                    { isLoading ? <CircularProgress size={25} color="inherit" /> : 'Create Account'}
                </IntentButton>
                {errorMsg && <Alert className={classes.padded} severity="error">{errorMsg}</Alert>}
                <Typography variant="body2" className={classes.padded}>
                    Already have an account?&nbsp;
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link component="button" variant="body2" onClick={() => history.push(ROUTES.LOGIN)}>Log in</Link>
                </Typography>
            </Box>
        </Fade>
    );
}
