import moment from 'moment';

export function getMealsForDay(meals, date) {
    if (!meals) return [];
    return meals.filter((meal) => moment(meal.date).isSame(date, 'day'));
}

export function getMealsForDateRange(meals, startDate, endDate) {
    if (!meals) return [];
    return meals.filter((meal) => moment(meal.date).isBetween(moment(startDate), moment(endDate), 'day', '[]'));
}
