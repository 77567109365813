import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Prompt, useHistory, useLocation, useParams } from 'react-router';
import { Box, CircularProgress, Fade } from '@material-ui/core';
import * as queryString from 'query-string';
import TextField from '@material-ui/core/TextField';
import cloneDeep from 'lodash.clonedeep';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx';
import Backdrop from '@material-ui/core/Backdrop';
import { useRecipeManager } from '../../context-providers/recipe-manager-provider';
import TitleBar from '../nav/title-bar';
import { useMealPlanner } from '../../context-providers/meal-planner-provider';
import LabelList from '../label/label-list';
import IngredientList from '../ingredient/ingredient-list';
import AddMealDialog from '../dialog/add-meal-dialog';
import { getRecipePhoto } from '../../util/image-helper';
import ImageUploader from '../input/image-uploader';
import IntentButton, { Intent } from '../input/intent-button';
import ROUTES from '../../routes/routes';
import RecipeField from './recipe-field';
import RecipeMethod from './recipe-method';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import { useFriendships } from '../../context-providers/friendships-provider';
import { useHome } from '../../context-providers/home-provider';
import usePreventReload from '../../hooks/use-prevent-reload';
import areRecipesEqual from '../../util/recipe-helper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        position: 'sticky',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            top: '54px',
        },
        [theme.breakpoints.up('sm')]: {
            top: '64px',
        },
    },
    button: {
        margin: '5px 0',
    },
    notes: {
        marginTop: '4px',
        padding: '4px',
        whiteSpace: 'pre-wrap',
        width: '100%',
        minHeight: '30px',
    },
    fieldName: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    recipeImgContainer: {
        background: theme.palette.grey.A100,
        position: 'relative',
        height: '200px',
        overflow: 'hidden',
    },
    recipeImg: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    photoButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    noPhotoText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: theme.palette.grey['600'],
    },
    servesInput: {
        width: '120px',
        marginLeft: '10px',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px 0',
    },
    sharedBy: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 80px)',
        overflow: 'hidden',
    },
}));

export default function Recipe() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { getUserRecipe, getHomeRecipe, saveRecipe, removeRecipe, getFriendsRecipe, addRecipe } = useRecipeManager();
    const { addUserMeal, addHomeMeal } = useMealPlanner();
    const { friendships } = useFriendships();
    const { home } = useHome();
    const { recipeId, username } = useParams();
    const [showAddMealDialog, setShowAddMealDialog] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [carefullyCancel, setCarefullyCancel] = useState(false);

    const query = queryString.parse(location.search);
    const editMode = query.edit === 'true';
    const isHomeRecipe = query.homeRecipe === 'true';
    const isHome = home && !!home.id;

    const addMeal = isHome ? addHomeMeal : addUserMeal;
    const getRecipe = isHomeRecipe ? getHomeRecipe : getUserRecipe;

    let recipe = {};
    if (!username) {
        recipe = getRecipe(recipeId);
    }

    const [pendingRecipe, setPendingRecipe] = useState(recipe);
    const isShared = username || isHomeRecipe;
    const changesMade = !areRecipesEqual(recipe, pendingRecipe);

    usePreventReload(changesMade);

    useEffect(() => {
        if (carefullyCancel) {
            setCarefullyCancel(false);
            history.push(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carefullyCancel]);

    useEffect(() => {
        if (!isShared && !editMode && changesMade) {
            setPendingRecipe(getRecipe(recipeId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, recipe]);

    useEffect(() => {
        if (username) {
            const friendId = friendships.find((f) => f.username === username).id;
            getFriendsRecipe(friendId, recipeId).then(setPendingRecipe);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!pendingRecipe) {
        history.push(ROUTES.RECIPES);
        return <div />;
    }

    if (Object.keys(pendingRecipe).length === 0) {
        return <Backdrop open timeout={5000} addEndListener={() => {}}><CircularProgress color="inherit" /></Backdrop>;
    }

    if (editMode && isShared) {
        history.push(location.pathname);
    }

    function addMealToPlan(mealToAdd) {
        addMeal(mealToAdd).then(() => {
            setShowAddMealDialog(false);
        });
    }

    function openAddMealDialog() {
        setShowAddMealDialog(true);
    }

    function discardPendingRecipe() {
        setCarefullyCancel(true);
    }

    function savePendingRecipe() {
        const updatedRecipe = {
            ...pendingRecipe,
            name: pendingRecipe.name.trim(),
        };
        saveRecipe(cloneDeep(updatedRecipe));
        history.push(location.pathname);
    }

    function copyRecipe() {
        addRecipe(pendingRecipe); // TODO: Check for duplication and warn user, allow overwrite
    }

    function onPropChange(propName, value) {
        setPendingRecipe({
            ...pendingRecipe,
            [propName]: value,
        });
    }

    function onRecipeDelete() {
        removeRecipe(recipe.recipeId);
        history.push('/recipes');
    }

    function getFriendUsername(id) {
        return friendships.find((f) => f.id === id).username;
    }


    const servesValid = pendingRecipe.serves && pendingRecipe.serves > 0 && Number.isInteger(pendingRecipe.serves);

    return (
        <Box key={editMode ? 'recipeEdit' : 'recipeView'} className={classes.root}>
            <TitleBar title={pendingRecipe.name} editMode={editMode} setTitle={(name) => onPropChange('name', name)} onBack={() => history.push('/recipes')} />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    <Box className={classes.recipeImgContainer}>
                        {pendingRecipe.img ? (
                            <img
                                className={classes.recipeImg}
                                src={getRecipePhoto(pendingRecipe.img)}
                                alt={pendingRecipe.name}
                            />
                        )
                            : (
                                <Typography className={classes.noPhotoText}>
                                    No Photo
                                </Typography>
                            )}
                        {editMode && (
                            <ImageUploader
                                className={classes.photoButton}
                                buttonText={`${pendingRecipe.img ? 'Edit' : 'Add'} photo`}
                                onChange={(img) => onPropChange('img', img)}
                            />
                        )}
                    </Box>
                    {editMode ? (
                        <Box className={classes.buttons}>
                            <IntentButton
                                className={classes.button}
                                disabled={!servesValid || !changesMade}
                                onClick={() => savePendingRecipe()}
                                variant="contained"
                                intent={Intent.PRIMARY}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </IntentButton>
                            <IntentButton
                                className={classes.button}
                                onClick={() => discardPendingRecipe()}
                                variant="contained"
                                intent={Intent.TERTIARY}
                                startIcon={<CancelIcon />}
                            >
                                Cancel
                            </IntentButton>
                        </Box>
                    )
                        : (
                            <Box className={classes.buttons}>
                                { !isShared && (
                                    <>
                                        <IntentButton
                                            className={classes.button}
                                            onClick={() => history.push({ pathname: location.pathname, search: '?edit=true' })}
                                            variant="contained"
                                            intent={Intent.PRIMARY}
                                            startIcon={<EditIcon fontSize="small" />}
                                        >
                                            Edit
                                        </IntentButton>
                                        <IntentButton
                                            className={classes.button}
                                            onClick={() => {
                                                setSelectedRecipe(pendingRecipe);
                                                setShowDeleteDialog(true);
                                            }}
                                            variant="contained"
                                            intent={Intent.ERROR}
                                            startIcon={<DeleteIcon />}
                                        >
                                            Delete
                                        </IntentButton>
                                    </>
                                ) }
                                { username && (
                                    <IntentButton
                                        className={classes.button}
                                        onClick={copyRecipe}
                                        variant="contained"
                                        intent={Intent.PRIMARY}
                                        startIcon={<CopyIcon />}
                                    >
                                        Copy to Recipes
                                    </IntentButton>
                                )}
                                <IntentButton
                                    className={classes.button}
                                    onClick={openAddMealDialog}
                                    variant="contained"
                                    intent={Intent.SECONDARY}
                                    startIcon={<AddIcon />}
                                >
                                    Add to Plan
                                </IntentButton>
                            </Box>
                        )}
                    {isShared && (
                        <RecipeField name="Created By" inline>
                            <Typography className={classes.sharedBy}>&nbsp;{username || getFriendUsername(recipe.sharedFrom)}</Typography>
                        </RecipeField>
                    )}
                    <RecipeField name="Labels" inline>
                        <LabelList recipe={pendingRecipe} setRecipe={setPendingRecipe} editMode={editMode} />
                    </RecipeField>
                    <RecipeField name="Serves" inline>
                        {editMode
                            ? (
                                <TextField
                                    id="standard-number"
                                    className={classes.servesInput}
                                    type="number"
                                    value={pendingRecipe.serves}
                                    onChange={(event) => setPendingRecipe({ ...pendingRecipe, serves: parseInt(event.target.value, 10) })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: '1', step: '1' }}
                                    error={!servesValid}
                                />
                            )
                            : (
                                <Typography>&nbsp;{pendingRecipe.serves}</Typography>
                            )}
                    </RecipeField>
                    <RecipeField name="Ingredients">
                        <IngredientList ingredients={pendingRecipe.ingredients} setIngredients={(ingredients) => onPropChange('ingredients', ingredients)} editMode={editMode} />
                    </RecipeField>
                    <RecipeField name="Method">
                        <RecipeMethod
                            method={pendingRecipe.method}
                            setMethod={(method) => onPropChange('method', method)}
                            editMode={editMode}
                        />
                    </RecipeField>
                    <RecipeField name="Notes">
                        {editMode
                            ? (
                                <TextField
                                    label="Notes"
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    value={pendingRecipe.notes ? pendingRecipe.notes : ''}
                                    onChange={(event) => setPendingRecipe({ ...pendingRecipe, notes: event.target.value })}
                                />
                            )
                            : (
                                <Typography className={clsx(classes.notes, { [classes.emptyText]: !pendingRecipe.notes })} variant="body1" align="center">
                                    {pendingRecipe.notes ? pendingRecipe.notes : 'No Notes'}
                                </Typography>
                            )}

                    </RecipeField>
                    <Box id="bottom-shim" style={{ height: '90px' }} />
                    <AddMealDialog
                        open={showAddMealDialog}
                        handleClose={() => setShowAddMealDialog(false)}
                        recipe={recipe}
                        addMeal={addMealToPlan}
                    />
                    <ConfirmDeleteDialog
                        open={showDeleteDialog}
                        handleClose={() => setShowDeleteDialog(false)}
                        item={selectedRecipe}
                        onDelete={onRecipeDelete}
                        idProp="recipeId"
                        itemType="Recipe"
                    />
                    <Prompt
                        message={(loc, action) => (editMode && changesMade && ((action === 'POP' || carefullyCancel || location.pathname !== loc.pathname))
                            ? 'Are you sure you want to leave this page? You have unsaved changes'
                            : true)}
                    />
                </Box>
            </Fade>
        </Box>

    );
}
