import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Fab, Fade, InputBase, Link } from '@material-ui/core';
import { useHistory } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useRecipeManager } from '../../context-providers/recipe-manager-provider';
import TitleBar from '../nav/title-bar';
import RecipeGrid from './recipe-grid';
import NewRecipeDialog from './new-recipe-dialog';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import { useHome } from '../../context-providers/home-provider';

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'inline-flex',
        backgroundColor: theme.palette.background.paper,
    },
    searchContainer: {
        position: 'fixed',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        zIndex: 5,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#7f7f7f',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        width: '100%',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(12),
        right: theme.spacing(4),
        zIndex: 2,
    },
}));

export default function Recipes() {
    const classes = useStyles();
    const history = useHistory();
    const { userRecipes, homeRecipes, addRecipe, removeRecipe } = useRecipeManager();
    const [recipeFilter, setRecipeFilter] = useState('');
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const { home } = useHome();
    const isHome = home && !!home.id;

    function filterRecipes(recipe) {
        return recipe.name.toLocaleLowerCase().includes(recipeFilter)
            || recipe.labels.findIndex((label) => label.toLocaleLowerCase().includes(recipeFilter)) >= 0;
    }

    function sortRecipes(a, b) {
        return a.name.localeCompare(b.name);
    }

    function addNewRecipe(recipe) {
        setAddDialogOpen(false);
        addRecipe(recipe).then((newId) => {
            history.push({
                pathname: `/recipes/${newId}`,
                search: '?edit=true',
            });
        });
    }

    const filteredRecipes = userRecipes ? [...userRecipes, ...homeRecipes].filter(filterRecipes).sort(sortRecipes) : null;

    return (
        <Box>
            <TitleBar title="Recipes" />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    <Box className={classes.searchContainer}>
                        <Paper className={classes.search}>
                            <Box className={classes.searchIcon}>
                                <SearchIcon />
                            </Box>
                            <InputBase
                                placeholder="Search recipes…"
                                inputProps={{ 'aria-label': 'search recipes' }}
                                onChange={(e) => setRecipeFilter(e.target.value.toLocaleLowerCase())}
                            />
                        </Paper>
                    </Box>
                    <Box id="top-shim" style={{ height: '60px' }} />
                    <RecipeGrid
                        showOptions
                        isHome={isHome}
                        recipes={filteredRecipes}
                        onDelete={removeRecipe}
                        emptyText={(
                            <span>It looks like you don&apos;t have any recipes. Why don&apos;t you&nbsp;
                                <Link component="button" onClick={() => setAddDialogOpen(true)}><Typography variant="body1">add one</Typography></Link>
                            </span>
                        )}
                    />
                    <Box id="bottom-shim" style={{ height: '90px' }} />
                    <Fab onClick={() => setAddDialogOpen(true)} color="primary" aria-label="add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                    <NewRecipeDialog open={addDialogOpen} handleClose={() => setAddDialogOpen(false)} addRecipe={addNewRecipe} />
                </Box>
            </Fade>
        </Box>
    );
}
