import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { UNITS } from '../../constants';

const useStyles = makeStyles(() => ({
    halfWidthInput: {
        width: '50%',
    },
}));

const ALLOWED_AMOUNT_CHARS = /^([1-9][0-9]*? )?[1-9][0-9]*\/[1-9][0-9]*$/m;

IngredientDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    editIndex: PropTypes.number,
    ingredients: PropTypes.arrayOf(PropTypes.object).isRequired,
    setIngredients: PropTypes.func.isRequired,
};

IngredientDialog.defaultProps = {
    editIndex: null,
};
export default function IngredientDialog(props) {
    const isUpdate = props.editIndex != null && props.editIndex >= 0;
    const initialIngredient = isUpdate ? props.ingredients[props.editIndex] : {};
    const [ingredient, setIngredient] = useState(initialIngredient);
    const unitRef = useRef(false);
    const nameRef = useRef(false);
    const infoRef = useRef(false);

    useEffect(() => {
        setIngredient(initialIngredient);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const classes = useStyles();

    function addIngredient() {
        props.setIngredients([
            ...props.ingredients,
            { ...ingredient,
                amount: ingredient.amount ? ingredient.amount.trim() : '',
                name: ingredient.name.trim(),
                unit: ingredient.unit ? ingredient.unit.trim() : '',
                info: ingredient.info ? ingredient.info.trim() : '',
            },
        ]);
        props.handleClose();
    }

    function updateIngredient() {
        const updateIngredients = cloneDeep(props.ingredients);
        updateIngredients[props.editIndex] = {
            ...ingredient,
            amount: ingredient.amount ? ingredient.amount.trim() : '',
            name: ingredient.name.trim(),
            unit: ingredient.unit ? ingredient.unit.trim() : '',
            info: ingredient.info ? ingredient.info.trim() : '',
        };
        props.setIngredients(updateIngredients);
        props.handleClose();
    }

    function setPendingIngredient(change) {
        setIngredient({ ...ingredient, ...change });
    }

    function keyPressed(e, nextFieldRef) {
        if (e.charCode === 13) {
            nextFieldRef.current.focus();
            e.preventDefault();
        }
    }

    const amountValid = !ingredient.amount || ALLOWED_AMOUNT_CHARS.test(ingredient.amount)
        || (!Number.isNaN(Number(ingredient.amount)) && Number.parseFloat(ingredient.amount) > 0);
    const ingredientValid = ingredient.name && amountValid;

    return (
        <Dialog open={props.open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{isUpdate ? 'Edit Ingredient' : 'New Ingredient'}</DialogTitle>
            <DialogContent>
                <Typography gutterBottom variant="body1">
                    Enter the ingredient details
                </Typography>
                <Box style={{ display: 'flex' }}>
                    <TextField
                        autoFocus
                        className={classes.halfWidthInput}
                        margin="dense"
                        id="amount"
                        label="Amount"
                        variant="outlined"
                        value={ingredient.amount}
                        onChange={(event) => setPendingIngredient({ amount: event.target.value })}
                        InputProps={{ onKeyPress: (e) => keyPressed(e, unitRef) }}
                        error={!amountValid}
                    />
                    &nbsp;
                    <Autocomplete
                        className={classes.halfWidthInput}
                        options={UNITS}
                        freeSolo
                        value={ingredient.unit}
                        inputValue={ingredient.unit}
                        onInputChange={(_, unit) => setPendingIngredient({ unit })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={unitRef}
                                style={{ width: '100%' }}
                                margin="dense"
                                label="Unit"
                                variant="outlined"
                                InputProps={{ onKeyPress: (e) => keyPressed(e, nameRef) }}
                            />
                        )}
                    />
                </Box>
                <TextField
                    inputRef={nameRef}
                    required
                    margin="dense"
                    id="ingredient-name"
                    label="Ingredient Name"
                    variant="outlined"
                    fullWidth
                    value={ingredient.name || ''}
                    onChange={(event) => setPendingIngredient({ name: event.target.value.replace(/\s\s+/g, ' ') })}
                    error={!ingredient.name}
                    InputProps={{ onKeyPress: (e) => keyPressed(e, infoRef) }}
                />
                <TextField
                    inputRef={infoRef}
                    margin="dense"
                    id="info"
                    label="Info"
                    variant="outlined"
                    fullWidth
                    value={ingredient.info || ''}
                    onChange={(event) => setPendingIngredient({ info: event.target.value.replace(/\s\s+/g, ' ') })}
                    helperText="e.g finely chopped, beaten, sliced"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()}>
                    Cancel
                </Button>
                {isUpdate
                    ? (
                        <Button disabled={!ingredientValid} onClick={() => updateIngredient()} variant="contained" color="primary">
                            Update
                        </Button>
                    )
                    : (
                        <Button disabled={!ingredientValid} onClick={() => addIngredient()} variant="contained" color="primary">
                            Add
                        </Button>
                    )}
            </DialogActions>
        </Dialog>
    );
}
