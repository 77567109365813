import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';

CustomSnackbar.propTypes = {
    SnackbarProps: PropTypes.shape({ onClose: PropTypes.func }).isRequired,
    ButtonProps: PropTypes.shape({}).isRequired,
    customParameters: PropTypes.shape({ severity: PropTypes.string }),
    message: PropTypes.string,
    action: PropTypes.string,
};

CustomSnackbar.defaultProps = {
    customParameters: {},
    message: null,
    action: null,
};

export default function CustomSnackbar(props) {
    return (
        <Snackbar {...props.SnackbarProps}>
            <Alert
                elevation={6}
                variant="filled"
                onClose={props.SnackbarProps.onClose}
                severity={props.customParameters.severity || 'info'}
                action={props.action != null && (
                    <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        {...props.ButtonProps}
                    >
                        {props.action}
                    </Button>
                )}
            >
                {props.message}
            </Alert>
        </Snackbar>
    );
}
