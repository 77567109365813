import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

export function formatSingleDate(date) {
    return moment(date).format(DEFAULT_DATE_FORMAT);
}

export function formatDateRange(startDate, endDate) {
    if (moment(startDate).isSame(moment(endDate), 'day')) {
        return moment(startDate).format(DEFAULT_DATE_FORMAT);
    }
    if (moment(startDate).isSame(moment(endDate), 'month')) {
        return `${moment(startDate).format('DD')} - ${formatSingleDate(endDate)}`;
    }
    if (moment(startDate).isSame(moment(endDate), 'year')) {
        return `${moment(startDate).format('DD MMM')} - ${formatSingleDate(endDate)}`;
    }
    return `${formatSingleDate(startDate)} - ${formatSingleDate(endDate)}`;
}
