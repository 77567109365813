import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

UserAvatar.propTypes = {
    user: PropTypes.shape({
        username: PropTypes.string,
        img: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

UserAvatar.defaultProps = {
    className: null,
};

export default function UserAvatar({ user, className }) {
    return (
        <Avatar alt={user.username.toLocaleUpperCase()} src={user.img || '#'} className={className} />
    );
}