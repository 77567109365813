import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { useDebounce } from 'use-lodash-debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import TickIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/styles';
import { useSettings } from '../../context-providers/settings-provider';
import { FRIENDSHIP_STATUS, useFriendships } from '../../context-providers/friendships-provider';
import { useAuth } from '../../context-providers/auth-provider';
import UserAvatar from '../general/user-avatar';

const useStyles = makeStyles((theme) => ({
    userExists: {
        color: theme.palette.success.main,
    },
    noUser: {
        color: theme.palette.error.main,
    },
    avatar: {
        margin: '0 10px 5px 0',
    },
}));

AddFriendDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default function AddFriendDialog(props) {
    const [userSearch, setUserSearch] = useState('');
    const [foundUser, setFoundUser] = useState(null);
    const { addFriend, acceptFriendship, friendships } = useFriendships();
    const { getUserByUsername } = useSettings();
    const { currentUser } = useAuth();
    const debouncedSearch = useDebounce(userSearch, 500);
    const classes = useStyles();

    useEffect(() => {
        if (userSearch) {
            setFoundUser(null);
            getUserByUsername(userSearch).then((user) => setFoundUser(user || {}))
                .catch(() => setFoundUser({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch]);

    let startAdornment = null;
    let endAdornment = (
        <InputAdornment position="end">
            <CrossIcon className={classes.noUser} />
        </InputAdornment>
    );
    let helperText;
    let addButtonDisabled = true;
    const existingFriendship = foundUser ? friendships.find((f) => f.id === foundUser.id) : null;

    if (!userSearch) {
        endAdornment = <div />;
        helperText = '';
    } else if (foundUser == null) {
        helperText = 'Searching...';
        endAdornment = (
            <InputAdornment position="end">
                <CircularProgress size={25} />
            </InputAdornment>
        );
    } else if (foundUser.id === currentUser.uid) {
        helperText = "That's you!";
    } else if (!foundUser.username) {
        helperText = 'No users match that username';
    } else if (existingFriendship) {
        if (existingFriendship.status === FRIENDSHIP_STATUS.FRIEND) {
            helperText = 'Already added'; // TODO: Maybe figure out if they have accepted or not
        } else if (existingFriendship.status === FRIENDSHIP_STATUS.BLOCKED) {
            helperText = 'You have blocked this user';
        }
    } else {
        addButtonDisabled = false;
        startAdornment = <UserAvatar user={foundUser} className={classes.avatar} />;
        endAdornment = (
            <InputAdornment position="end">
                <TickIcon className={classes.userExists} />
            </InputAdornment>
        );
    }

    function close() {
        setUserSearch('');
        setFoundUser(null);
        props.handleClose();
    }

    function submit() {
        if (existingFriendship && existingFriendship.status === FRIENDSHIP_STATUS.RECEIVED) {
            acceptFriendship(foundUser.id);
        } else {
            addFriend(foundUser);
        }
        close();
    }

    return (
        <Dialog open={props.open} onClose={() => close()} aria-labelledby="form-dialog-title">
            <DialogTitle>Add Friend</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Search for friends by username
                </DialogContentText>
                { }
                <TextField
                    autoFocus
                    margin="dense"
                    label="Username"
                    fullWidth
                    value={userSearch}
                    onChange={(event) => setUserSearch(event.target.value.trim())}
                    helperText={helperText}
                    InputProps={{ startAdornment, endAdornment }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>
                    Cancel
                </Button>
                <Button disabled={addButtonDisabled} onClick={submit} variant="contained" color="primary">
                    Add Friend
                </Button>
            </DialogActions>
        </Dialog>
    );
}
