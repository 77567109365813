import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Fab, Fade } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ReceivedInvitationList from './received-invitation-list';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import TitleBar from '../nav/title-bar';
import AddFriendDialog from './add-friend-dialog';
import FriendList from './friend-list';
import SentInvitationList from './sent-invitation-list';

const useStyles = makeStyles((theme) => ({
    heading: {
        margin: '10px auto',
        fontWeight: 300,
        textAlign: 'center',
    },
    divider: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(12),
        right: theme.spacing(4),
        zIndex: 2,
    },
}));

export default function Friends() {
    const classes = useStyles();
    const [addFriendDialogOpen, setAddFriendDialogOpen] = useState(false);

    function addFriend() {
        setAddFriendDialogOpen(true);
    }

    return (
        <Box>
            <TitleBar title="Friends" />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    <Typography variant="h5" className={classes.heading}>
                        Invitations
                    </Typography>
                    <ReceivedInvitationList />
                    <Divider className={classes.divider} variant="middle" />
                    <Typography variant="h5" className={classes.heading}>
                        Pending Friend Requests
                    </Typography>
                    <SentInvitationList />
                    <Divider className={classes.divider} variant="middle" />
                    <Typography variant="h5" className={classes.heading}>
                        Friends
                    </Typography>
                    <FriendList />
                    <Divider className={classes.divider} variant="middle" />
                    <Fab onClick={addFriend} color="primary" aria-label="add" className={classes.fab}>
                        <PersonAddIcon />
                    </Fab>
                    <AddFriendDialog open={addFriendDialogOpen} handleClose={() => setAddFriendDialogOpen(false)} />
                </Box>
            </Fade>
        </Box>
    );
}
