import React, { useState } from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSettings } from '../../context-providers/settings-provider';
import IntentButton, { Intent } from '../input/intent-button';
import { HOME_ROLES, useHome } from '../../context-providers/home-provider';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import RemoveHomeDialog from './remove-home-dialog';
import { useAuth } from '../../context-providers/auth-provider';
import { useInvitations } from '../../context-providers/invitations-provider';
import HomeInvitation from './home-invitation';
import { useFriendships } from '../../context-providers/friendships-provider';
import HomeMember from './home-member';
import HomeMemberDialog from './home-member-dialog';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px',
    },
    loading: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    subHeading: {
        color: theme.palette.grey.A400,
        fontWeight: 600,
    },
    roleIcon: {
        height: '40px',
        width: '40px',
        marginRight: '5px',
    },
    roleName: {
        color: theme.palette.grey.A200,
    },
    deleteButton: {
        padding: '5px 4px',
        color: theme.palette.error.main,
    },
    list: {
        width: '100%',
    },
    buttonRow: {
        display: 'flex',
        margin: '10px auto',
    },
    exit: {
        maxHeight: '60px',
        overflow: 'hidden',
    },
    exitActive: {
        maxHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'all 500ms',
        overflow: 'hidden',
    },
    enter: {
        maxHeight: 0,
        transition: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        overflow: 'hidden',
    },
    enterActive: {
        maxHeight: '60px',
        paddingTop: '8px',
        paddingBottom: '8px',
        transition: 'all 500ms',
        overflow: 'hidden',
    },
}));

export default function HomeSettings() {
    const classes = useStyles();
    const { currentUser } = useAuth();
    const { privateSettings } = useSettings();
    const { home, addHome, removeHome, addMember, updateMemberRole, removeMember, acceptHomeInvitation, rejectHomeInvitation } = useHome();
    const { homeInvitations } = useInvitations();
    const { friends, getFriend } = useFriendships();
    const [isLoading, setIsLoading] = useState(false);
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false);
    const [showMemberDialog, setShowMemberDialog] = useState(false);
    const [showDeleteHomeDialog, setShowDeleteHomeDialog] = useState(false);


    function onCreateHome() {
        addHome().finally(() => setIsLoading(false));
    }

    function onMemberClicked(event, member) {
        setSelectedMember(member);
        setOptionsAnchorEl(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    }

    function onRemoveMember(memberId) {
        setOptionsAnchorEl(null);
        removeMember(memberId);
    }

    function onRemoveHome() {
        setOptionsAnchorEl(null);
        removeHome();
    }

    function onSaveMember(member, roleId) {
        setOptionsAnchorEl(null);
        if (selectedMember) {
            updateMemberRole(member, roleId);
        } else {
            addMember(member, roleId);
        }
    }

    function getMyMembership() {
        return home.members.find((member) => member.id === currentUser.uid);
    }

    if (!privateSettings.homeId) {
        if (!homeInvitations || !friends) return <Box className={classes.loading}><CircularProgress /></Box>;
        if (homeInvitations.length) {
            const invite = { ...homeInvitations[0], sender: getFriend(homeInvitations[0].senderId) };
            return (
                <Box className={classes.root}>
                    <HomeInvitation key={invite.id} invite={invite} acceptInvite={acceptHomeInvitation} rejectInvite={rejectHomeInvitation} />
                </Box>
            );
        }
        return (
            <Box className={classes.root}>
                <Typography className={classes.emptyText} variant="body1" align="center">
                    You are not part of a home
                </Typography>
                <IntentButton
                    onClick={() => onCreateHome()}
                    intent={Intent.PRIMARY}
                    startIcon={<AddIcon />}
                    variant="contained"
                >
                    { isLoading ? <CircularProgress size={25} color="inherit" /> : 'Create Home'}
                </IntentButton>
            </Box>
        );
    }

    if (!home || !home.members) {
        return <Box className={classes.loading}><CircularProgress /></Box>;
    }

    const myRole = getMyMembership().role;
    const confirmedMembers = [];
    const unconfirmedMembers = [];
    home.members.forEach((member) => {
        if (member.confirmed) {
            confirmedMembers.push(member);
        } else {
            unconfirmedMembers.push(member);
        }
    });

    function getMemberList(members) {
        return (
            <List className={classes.list}>
                <TransitionGroup>
                    { members.map((member) => (
                        <CSSTransition
                            key={member.id}
                            timeout={500}
                            classNames={{
                                enter: classes.enter,
                                enterActive: classes.enterActive,
                                exit: classes.exit,
                                exitActive: classes.exitActive,
                            }}
                        >
                            <HomeMember
                                member={member}
                                onMemberClicked={member.id === currentUser.uid || (myRole && myRole.id === HOME_ROLES.HEAD_CHEF.id) ? onMemberClicked : null}
                            />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </List>
        );
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.subHeading} variant="body1" align="center">
                Members
            </Typography>
            { getMemberList(confirmedMembers) }
            {!!unconfirmedMembers.length && (
                <>
                    <Typography className={classes.subHeading} variant="body1" align="center">
                        Invited
                    </Typography>
                    { getMemberList(unconfirmedMembers) }
                </>
            )}
            <Menu
                getContentAnchorEl={null}
                anchorEl={optionsAnchorEl}
                open={!!optionsAnchorEl}
                onClose={() => setOptionsAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {selectedMember && selectedMember.id !== currentUser.uid
                    && myRole && myRole.id === HOME_ROLES.HEAD_CHEF.id && (
                    <MenuItem
                        onClick={() => {
                            setShowMemberDialog(true);
                            setOptionsAnchorEl(null);
                        }}
                    >
                        <ListItemIcon>
                            <SettingsIcon fontSize="default" />
                        </ListItemIcon>
                        Change Role
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setShowDeleteMemberDialog(true);
                        setOptionsAnchorEl(null);
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="default" />
                    </ListItemIcon>
                    {selectedMember && selectedMember.id === currentUser.uid ? 'Leave Home' : 'Remove Member'}
                </MenuItem>
            </Menu>
            <ConfirmDeleteDialog
                open={showDeleteMemberDialog}
                handleClose={() => {
                    setOptionsAnchorEl(null);
                    setShowDeleteMemberDialog(false);
                }}
                item={selectedMember}
                onDelete={onRemoveMember}
                idProp="id"
                nameProp="username"
                itemType="Home Member"
                title={selectedMember && selectedMember.id === currentUser.uid ? 'Confirm Leave Home' : null}
                text={selectedMember && selectedMember.id === currentUser.uid ? 'Are you sure you want to leave this home?' : null}
                buttonText={selectedMember && selectedMember.id === currentUser.uid ? 'Leave' : 'Remove'}
            />
            {myRole.id === HOME_ROLES.HEAD_CHEF.id && (
                <Box className={classes.buttonRow}>
                    <IntentButton
                        onClick={() => {
                            setSelectedMember(null);
                            setOptionsAnchorEl(null);
                            setShowMemberDialog(true);
                        }}
                        intent={Intent.PRIMARY}
                        startIcon={<PersonAddIcon />}
                        variant="contained"
                    >
                        Add Member
                    </IntentButton>
                    <IntentButton
                        onClick={() => {
                            setOptionsAnchorEl(null);
                            setShowDeleteHomeDialog(true);
                        }}
                        intent={Intent.ERROR}
                        startIcon={<DeleteIcon />}
                        variant="contained"
                    >
                        Delete Home
                    </IntentButton>
                </Box>
            )}
            <RemoveHomeDialog
                open={showDeleteHomeDialog}
                handleClose={() => {
                    setOptionsAnchorEl(null);
                    setShowDeleteHomeDialog(false);
                }}
                onDelete={onRemoveHome}
            />
            <HomeMemberDialog
                open={showMemberDialog}
                member={selectedMember}
                handleClose={() => {
                    setOptionsAnchorEl(null);
                    setShowMemberDialog(false);
                }}
                saveMember={onSaveMember}
            />
            <br />
        </Box>
    );
}
