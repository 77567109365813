import React, { useEffect, useState } from 'react';
import { Fade, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import TickIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/Cancel';
import { useDebounce } from 'use-lodash-debounce';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import Image from 'material-ui-image';
import Typography from '@material-ui/core/Typography';
import IntentButton, { Intent } from '../components/input/intent-button';
import ROUTES from '../routes/routes';
import logo from '../resources/images/logo.svg';
import { DEFAULT_FADE_ANIMATION } from '../constants';
import { useSettings } from '../context-providers/settings-provider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px auto',
        width: '250px',
    },
    available: {
        color: theme.palette.success.main,
    },
    taken: {
        color: theme.palette.error.main,
    },
    padded: {
        margin: '10px auto 0 auto',
        textAlign: 'center',
    },
}));

const ALLOWED_USERNAME_CHARS = /^[\w_]+$/;
const USERNAME_AVAILABILITY = {
    CHECKING: 'CHECKING',
    AVAILABLE: 'AVAILABLE',
    TAKEN: 'TAKEN',
};

/**
 * Show on first login/when user is missing id @constructor
 */
export default function SetUp() {
    const [username, setUsername] = useState('');
    const [usernameAvailability, setUsernameAvailability] = useState(null);
    const debouncedUsername = useDebounce(username, 500);
    const classes = useStyles();
    const { getUserByUsername, createUsername } = useSettings();
    const history = useHistory();

    useEffect(() => {
        setUsernameAvailability(null);
        if (usernameLengthValid() && usernameContentValid()) {
            setUsernameAvailability(USERNAME_AVAILABILITY.CHECKING);
            // Username is valid, lets check if it's taken
            getUserByUsername(username)
                .then((userDetails) => setUsernameAvailability(userDetails ? USERNAME_AVAILABILITY.TAKEN : USERNAME_AVAILABILITY.AVAILABLE))
                .catch(() => setUsernameAvailability(null)); // TODO: And display an error
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedUsername]);

    function usernameContentValid() {
        return username && ALLOWED_USERNAME_CHARS.test(username);
    }

    function usernameLengthValid() {
        return username && username.length > 5 && username.length < 16;
    }

    function usernameValid() {
        return usernameLengthValid() && usernameContentValid() && usernameAvailability === USERNAME_AVAILABILITY.AVAILABLE;
    }

    function getUsernameStatusIcon() {
        if (usernameAvailability === USERNAME_AVAILABILITY.CHECKING) {
            return (
                <InputAdornment position="end">
                    <CircularProgress size={25} />
                </InputAdornment>
            );
        }
        if (usernameAvailability === USERNAME_AVAILABILITY.AVAILABLE) {
            return (
                <InputAdornment position="end">
                    <TickIcon className={classes.available} />
                </InputAdornment>
            );
        }
        if (usernameAvailability === USERNAME_AVAILABILITY.TAKEN) {
            return (
                <InputAdornment position="end">
                    <CrossIcon className={classes.taken} />
                </InputAdornment>
            );
        }
        return <div />;
    }

    function getUsernameHelperText() {
        if (usernameAvailability === USERNAME_AVAILABILITY.AVAILABLE) {
            return 'Username is available';
        }
        if (usernameAvailability === USERNAME_AVAILABILITY.TAKEN) {
            return 'This username is already in use';
        }
        if (!usernameLengthValid()) {
            return 'Must be between 6 and 16 characters';
        }
        if (!usernameContentValid()) {
            return 'Only letters, numbers and underscores allowed';
        }
        return null;
    }

    function onSubmit() {
        createUsername(username).then(() => history.push(ROUTES.HOME));
    }

    return (
        <Fade in timeout={DEFAULT_FADE_ANIMATION}>
            <Box className={classes.root}>
                <Image disableTransition src={logo} style={{ width: '100px', height: '120px', padding: 0 }} />
                <Typography gutterBottom variant="h6" className={classes.padded}>
                    Welcome to Cook&apos;d!
                </Typography>
                <Typography className={classes.padded}>
                    To get started, please create your username
                </Typography>
                <TextField
                    className={classes.padded}
                    autoFocus
                    id="username"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    error={username && (!usernameContentValid() || !usernameLengthValid() || usernameAvailability === USERNAME_AVAILABILITY.TAKEN)}
                    helperText={username && getUsernameHelperText()}
                    InputProps={{ endAdornment: getUsernameStatusIcon() }}
                />
                <IntentButton disabled={!usernameValid()} onClick={onSubmit} variant="contained" className={classes.padded} intent={Intent.PRIMARY}>
                    Get Cooking!
                </IntentButton>
            </Box>
        </Fade>
    );
}
