import React, { createContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDataStore } from './datastore-provider';
import { useFirebase } from '../firebase/firebase-provider';

const SettingsContext = createContext({});
export const useSettings = () => React.useContext(SettingsContext);

const COLLECTION_NAME = 'settings';

SettingsProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function SettingsProvider(props) {
    const [privateSettings, setPrivateSettings] = useState(null);
    const [publicSettings, setPublicSettings] = useState(null);
    const { userDb } = useDataStore();
    const fb = useFirebase();

    // Private
    useEffect(() => {
        if (userDb) {
            userDb.collection(COLLECTION_NAME).doc('private')
                .onSnapshot((doc) => {
                    if (!doc.exists) {
                        setPrivateSettings({});
                    } else {
                        setPrivateSettings(doc.data());
                    }
                }, (error) => {
                    console.debug(error);
                    setPrivateSettings(privateSettings || {});
                    // TODO: Let the user know something went wrong
                });
        } else if (userDb === null) {
            setPrivateSettings({});
        } else {
            setPrivateSettings(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDb]);

    // Public
    useEffect(() => {
        if (userDb) {
            userDb
                .onSnapshot((doc) => {
                    if (doc.exists || doc.data()) {
                        setPublicSettings(doc.data());
                    } else {
                        setPublicSettings({});
                    }
                }, (error) => {
                    console.debug(error);
                    setPublicSettings(publicSettings || {});
                    // TODO: Let the user know something went wrong
                });
        } else if (userDb === null) {
            setPublicSettings({});
        } else {
            setPublicSettings(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDb]);

    function getUserByUsername(username) {
        return new Promise((resolve, reject) => {
            if (!username) resolve(null);
            fb.firestore().collection('users').where('username', '==', username.toLocaleLowerCase())
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.docs || !querySnapshot.docs.length) {
                        resolve(null);
                    } else {
                        const doc = querySnapshot.docs[0];
                        resolve({ ...doc.data(), id: doc.id });
                    }
                })
                .catch((err) => {
                    console.debug(err);
                    reject(err);
                });
        });
    }

    function getUsersById(ids) {
        return new Promise((resolve) => {
            if (!ids || !ids.length) resolve([]);
            Promise.all(ids.map(getUserById)).then((users) => {
                resolve(users.filter(Boolean)); // Filter out nulls in case there were errors
            });
        });
    }

    function getUserById(id) {
        return new Promise((resolve) => {
            if (!id) resolve(null);
            fb.firestore().collection('users').doc(id)
                .get()
                .then((doc) => {
                    if (!doc.exists) {
                        resolve(null);
                    } else {
                        resolve({ ...doc.data(), id: doc.id });
                    }
                })
                .catch((err) => {
                    console.debug(err);
                });
        });
    }

    function getUsersByUsername(usernames) {
        return new Promise((resolve, reject) => {
            if (!usernames || !usernames.length) {
                resolve([]);
            } else {
                const lowerUsernames = usernames.map((u) => u.toLocaleLowerCase());
                fb.firestore().collection('users').where('username', 'in', lowerUsernames)
                    .get()
                    .then((querySnapshot) => {
                        if (!querySnapshot.docs || !querySnapshot.docs.length) {
                            resolve([]);
                        } else {
                            resolve(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                            // const doc = querySnapshot.docs[0];
                            // resolve({ ...doc.data(), id: doc.id });
                        }
                    })
                    .catch((err) => {
                        console.debug(err);
                        reject(err);
                    });
            }
        });
    }

    function createUsername(username) {
        return new Promise((resolve, reject) => userDb
            .set({ username: username.toLocaleLowerCase() }, { merge: true })
            .then(() => {
                resolve();
            })
            .catch((err) => {
                console.debug(err);
                reject(err);
            }));
    }

    function updatePublicSettings(newSettings) {
        if (userDb) {
            userDb.update(newSettings).then(() => {});
        }
    }

    function updatePrivateSettings(change) {
        if (userDb) {
            userDb.collection(COLLECTION_NAME).doc('private')
                .set(change, { merge: true }).then(() => {});
        }
    }

    function setHomeId(id) {
        updatePrivateSettings({ homeId: id });
    }

    return (
        <SettingsContext.Provider value={{ publicSettings, privateSettings, getUserByUsername, getUsersByUsername, createUsername, updateSettings: updatePublicSettings, getUsersById, setHomeId }}>
            { props.children }
        </SettingsContext.Provider>
    );
}
