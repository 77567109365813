import React from 'react';
import { Box, CircularProgress, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import * as moment from 'moment';
import TitleBar from '../nav/title-bar';
import RecipeGrid from '../recipe/recipe-grid';
import { useMealPlanner } from '../../context-providers/meal-planner-provider';
import { getMealsForDay } from '../../util/meal-helper';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import HomeSettings from './home-settings';
import { useHome } from '../../context-providers/home-provider';

const useStyles = makeStyles(() => ({
    heading: {
        margin: '10px auto',
        fontWeight: 300,
        textAlign: 'center',
    },
    divider: {
        marginTop: '10px',
        marginBottom: '5px',
    },
    loading: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function Home() {
    const classes = useStyles();
    const { userMeals, homeMeals } = useMealPlanner();
    const { home } = useHome();

    if (!home) {
        return <Box className={classes.loading}><CircularProgress color="inherit" /></Box>;
    }

    const isHome = home && !!home.id;
    const meals = isHome ? homeMeals : userMeals;
    const todayMeals = meals ? getMealsForDay(meals, moment()) : null;
    const tomorrowMeals = meals ? getMealsForDay(meals, moment().add(1, 'days')) : null;

    return (
        <Box>
            <TitleBar static title="Home" />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box>
                    {home ? (
                        <>
                            <Typography variant="h5" className={classes.heading}>
                                Today
                            </Typography>
                            <RecipeGrid recipes={todayMeals} isMeals isHome={isHome} emptyText="No meals planned for today" />
                            <Divider className={classes.divider} variant="middle" />
                            <Typography variant="h5" className={classes.heading}>
                                Tomorrow
                            </Typography>
                            <RecipeGrid recipes={tomorrowMeals} isMeals isHome={isHome} emptyText="No meals planned for tomorrow" />
                            <Divider className={classes.divider} variant="middle" />
                            <Typography variant="h5" className={classes.heading}>
                                Your Home
                            </Typography>
                            <HomeSettings />
                        </>
                    )
                        : <Box className={classes.loading}><CircularProgress color="inherit" /></Box>}
                </Box>
            </Fade>
            <Box id="bottom-shim" style={{ height: '90px' }} />
        </Box>
    );
}
