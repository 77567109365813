import React, { useState } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { Box, ListItemText } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import AddIcon from '@material-ui/icons/Add';
import IngredientDialog from './ingredient-dialog';
import IngredientListItem from './ingredient-list-item';
import IntentButton, { Intent } from '../input/intent-button';

const useStyles = makeStyles(() => ({
    newIngredientButton: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px auto 0 auto',
        width: '100%',
    },
    noIngredients: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: '#999999',
    },
    root: {
        width: '100%',
    },
}));

IngredientList.propTypes = {
    ingredients: PropTypes.arrayOf(PropTypes.object).isRequired,
    setIngredients: PropTypes.func,
    editMode: PropTypes.bool,
    multiplier: PropTypes.number,
    showCheckBoxes: PropTypes.bool,
    handleCheck: PropTypes.func,
};
IngredientList.defaultProps = {
    editMode: false,
    multiplier: 1,
    showCheckBoxes: false,
    handleCheck: null,
    setIngredients: () => {},
};

export default function IngredientList(props) {
    const [selectedIngredientIndex, setSelectedIngredientIndex] = useState(null);
    const [ingredientDialogOpen, setIngredientDialogOpen] = useState(false);

    const classes = useStyles();

    function removeIngredient(index) {
        setSelectedIngredientIndex(null);
        const updateIngredients = cloneDeep(props.ingredients);
        updateIngredients.splice(index, 1);
        props.setIngredients(updateIngredients);
    }

    function newIngredient() {
        setSelectedIngredientIndex(null);
        setIngredientDialogOpen(true);
    }

    function updateIngredient(index) {
        setSelectedIngredientIndex(index);
        setIngredientDialogOpen(true);
    }

    function ingredientComparator(a, b) {
        if (!!a.checked === !!b.checked) {
            return a.name.localeCompare(b.name);
        }
        return a.checked ? 1 : -1;
    }

    return (
        <List aria-label="ingredients" className={classes.root}>
            <Divider />
            {props.ingredients && props.ingredients.length
                ? props.ingredients.map((ingredient, index) => (
                    <IngredientListItem
                        key={`${ingredient.amount}-${ingredient.unit}-${ingredient.name}`}
                        ingredient={ingredient}
                        editMode={props.editMode}
                        multiplier={props.multiplier}
                        updateIngredient={() => updateIngredient(index)}
                        removeIngredient={() => removeIngredient(index)}
                        showCheckBoxes={props.showCheckBoxes}
                        handleCheck={() => props.handleCheck(index)}
                    />
                )).sort((a, b) => ingredientComparator(a.props.ingredient, b.props.ingredient))
                : (
                    <ListItem divider>
                        <ListItemText className={classes.noIngredients} primary="No Ingredients" />
                    </ListItem>
                )}
            {props.editMode
                && (
                    <Box className={classes.newIngredientButton}>
                        <IntentButton onClick={() => newIngredient()} intent={Intent.PRIMARY} startIcon={<AddIcon />} variant="contained">
                            New Ingredient
                        </IntentButton>
                    </Box>
                )}
            <IngredientDialog
                open={ingredientDialogOpen}
                handleClose={() => {
                    setIngredientDialogOpen(false);
                }}
                editIndex={selectedIngredientIndex}
                ingredients={props.ingredients}
                setIngredients={props.setIngredients}
            />
        </List>
    );
}
