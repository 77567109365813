import React from 'react';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { useHistory, useLocation } from 'react-router';
import TodayIcon from '@material-ui/icons/Today';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/core/styles';
import ROUTES from '../../routes/routes';
import { useAuth } from '../../context-providers/auth-provider';
import { useSettings } from '../../context-providers/settings-provider';

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        width: '100%',
        height: '80px',
        position: 'static',
        '@media (min-height: 600px)': {
            position: 'fixed',
        },
        bottom: 0,
        background: theme.palette.primary.main,
        zIndex: 1,
    },
    action: {
        minWidth: '60px',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        transition: 'all 0.5s',
    },
    selected: {
        minWidth: '76px',
        color: `${theme.palette.secondary.main} !important`,
        fontSize: '16px !important',
        background: `${theme.palette.primary.contrastText} !important`,
        boxShadow: 'none !important',
    },
    label: {
        background: 'none !important',
        fontSize: '14px',
    },
}));

export default function BottomBar() {
    const history = useHistory();
    const location = useLocation();
    const { isVerified } = useAuth();
    const { publicSettings } = useSettings();
    const classes = useStyles();

    if (!isVerified() || !publicSettings || !publicSettings.username) {
        return <div />;
    }
    const locationValue = `/${location.pathname.split('/')[1]}`;

    const actionClasses = {
        selected: classes.selected,
        root: classes.action,
        label: classes.label,
    };

    return (
        <BottomNavigation
            value={locationValue}
            onChange={(event, newLocation) => {
                history.push(newLocation);
            }}
            showLabels
            className={classes.bottomBar}
        >
            <BottomNavigationAction classes={actionClasses} label="Recipes" value={ROUTES.RECIPES} icon={<RestaurantIcon />} />
            <BottomNavigationAction classes={actionClasses} label="Planner" value={ROUTES.PLANNER} icon={<TodayIcon />} />
            <BottomNavigationAction classes={actionClasses} label="Home" value={ROUTES.HOME} icon={<HomeIcon />} />
            <BottomNavigationAction classes={actionClasses} label="Shopping" value={ROUTES.SHOPPING} icon={<ShoppingCartIcon />} />
            <BottomNavigationAction classes={actionClasses} label="Friends" value={ROUTES.FRIENDS} icon={<PeopleIcon />} />
        </BottomNavigation>
    );
}
