import React, { useEffect, useRef } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import { DragHandle } from '@material-ui/icons';
import IntentButton, { Intent } from '../input/intent-button';

const useStyles = makeStyles((theme) => ({
    step: {
        marginTop: '4px',
        padding: '4px',
        whiteSpace: 'pre-wrap',
        width: '100%',
        minHeight: '30px',
    },
    stepSummary: {
        marginLeft: '10px',
        color: theme.palette.grey['600'],
        whiteSpace: 'nowrap',
    },
    stepPreviewContainer: {
        overflow: 'hidden',
        width: 'calc(100vw - 190px)',
    },
    stepPreview: {
        padding: '0 5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: theme.palette.grey['500'],
    },
}));

RecipeMethodStep.propTypes = {
    stepId: PropTypes.string.isRequired,
    stepValue: PropTypes.string,
    stepName: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    editMode: PropTypes.bool,
    handleExpansionChange: PropTypes.func.isRequired,
    updateStep: PropTypes.func.isRequired,
    deleteStep: PropTypes.func.isRequired,
    handleClass: PropTypes.string.isRequired,
};

RecipeMethodStep.defaultProps = {
    expanded: false,
    editMode: false,
    stepValue: '',
};

export default function RecipeMethodStep(props) {
    const classes = useStyles();
    const stepRef = useRef(null);

    useEffect(() => {
        if (props.editMode && props.expanded && stepRef) {
            setTimeout(() => {
                // scrollToComponent(stepRef, {});
                // window.scrollTo(0, stepRef.current.offsetTop);
                stepRef.current.scrollIntoView(true);
                window.scrollBy({ left: 0, top: -200, behavior: 'smooth' });
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepRef, props.expanded]);

    return (
        <ExpansionPanel
            expanded={props.expanded}
            onChange={(event, isExpanded) => props.handleExpansionChange(props.stepId, isExpanded)}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
            >
                {props.editMode && <DragHandle className={props.handleClass} />}
                <Typography className={classes.stepSummary}>{props.stepName}</Typography>
                {!props.expanded && (
                    <Typography component="div" className={classes.stepPreviewContainer}>
                        <div className={classes.stepPreview}>
                            {props.stepValue}
                        </div>
                    </Typography>
                )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                { props.editMode ? (
                    <TextField
                        ref={stepRef}
                        autoFocus
                        multiline
                        fullWidth
                        label="Step Details"
                        variant="outlined"
                        value={props.stepValue}
                        // onChange={(event) => setStep(event.target.value)}
                        onChange={(event) => props.updateStep({ id: props.stepId, step: event.target.value })}
                    />
                ) : (
                    <Typography className={classes.step}>
                        {props.stepValue}
                    </Typography>
                )}
            </ExpansionPanelDetails>
            {props.editMode && (
                <ExpansionPanelActions>
                    <IntentButton
                        onClick={() => props.deleteStep(props.stepId)}
                        variant="contained"
                        intent={Intent.ERROR}
                        startIcon={<DeleteIcon />}
                    >
                        Delete
                    </IntentButton>
                </ExpansionPanelActions>
            )}
        </ExpansionPanel>
    );
}
