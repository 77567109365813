import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    chip: {
        height: '26px',
        margin: '4px 0 4px 5px',
    },
}));

LabelPill.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
};

LabelPill.defaultProps = {
    onDelete: null,
};
export default function LabelPill(props) {
    const classes = useStyles();

    return (
        <Chip
            label={props.label}
            variant="outlined"
            color="primary"
            className={classes.chip}
            onDelete={props.onDelete}
        />
    );
}
