import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import GridListTile from '@material-ui/core/GridListTile';
import { useHistory, useLocation } from 'react-router';
import Box from '@material-ui/core/Box';
import * as PropTypes from 'prop-types';
import MoreIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import clsx from 'clsx';
import { getRecipePhoto } from '../../util/image-helper';
import ImagePlaceholder from '../general/image-placeholder';
import AddMealDialog from '../dialog/add-meal-dialog';
import { useMealPlanner } from '../../context-providers/meal-planner-provider';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import { useFriendships } from '../../context-providers/friendships-provider';
import UserAvatar from '../general/user-avatar';

const useStyles = makeStyles((theme) => ({
    grid: {
        // margin: '60px auto 60px auto !important',
        margin: '0 auto !important',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    gridTile: {
        flexShrink: 1,
        margin: '4px',
        [theme.breakpoints.down('sm')]: {
            height: '150px',
            width: '150px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '200px',
            width: '200px',
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            width: '200px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '300px',
            width: '300px',
        },
    },
    loading: {
        height: '150px',
        width: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    titleWrap: {
        height: '100%',
        width: 'calc(100% - 40px)',
        marginTop: '10px',
        marginLeft: '10px',
    },
    title: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        fontSize: '1rem',
        overflowWrap: 'normal',
        textOverflow: 'ellipsis',
        height: '43px',
        lineHeight: '20px',
        whiteSpace: 'normal',
    },
    subtitle: {
        width: 'calc(100% - 30px)',
    },
    actionButton: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    recipeOptionsButton: {
        padding: '5px',
        color: theme.palette.primary.contrastText,
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px',
    },
    sharedFrom: {
        position: 'absolute',
        top: 6,
        left: 6,
        width: '25px',
        height: '25px',
        zIndex: 1,
        fontSize: '16px',
        background: theme.palette.secondary.main,
    },
}));

RecipeGrid.propTypes = {
    recipes: PropTypes.arrayOf(PropTypes.shape({})),
    isMeals: PropTypes.bool,
    isFriends: PropTypes.bool,
    isHome: PropTypes.bool,
    showOptions: PropTypes.bool,
    emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onDelete: PropTypes.func,
};

RecipeGrid.defaultProps = {
    recipes: null,
    isMeals: false,
    isFriends: false,
    isHome: false,
    showOptions: false,
    emptyText: '',
    onDelete: null,
};
export default function RecipeGrid(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { addUserMeal, addHomeMeal } = useMealPlanner();
    const { getFriend } = useFriendships();
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddMealDialog, setShowAddMealDialog] = useState(false);

    const addMeal = props.isHome ? addHomeMeal : addUserMeal;

    let recipeLocation = '/recipes';
    let idProp = 'recipeId';
    if (props.isMeals) {
        recipeLocation = '/planner';
        idProp = 'mealId';
    } else if (props.isFriends) {
        recipeLocation = `${location.pathname}/recipes`;
    }

    function onRecipeOptionsClicked(event, recipe) {
        setOptionsAnchorEl(event.currentTarget);
        setSelectedRecipe(recipe);
        event.preventDefault();
        event.stopPropagation();
    }

    function openDeleteDialog() {
        setOptionsAnchorEl(false);
        setShowDeleteDialog(true);
    }

    function openAddMealDialog() {
        setOptionsAnchorEl(false);
        setShowAddMealDialog(true);
    }

    function addMealToPlan(meal) {
        addMeal(meal).then(() => {
            setShowAddMealDialog(false);
        });
    }

    function openRecipe(id, homeRecipe) {
        history.push({
            pathname: `${recipeLocation}/${id}`,
            search: `isHome=${props.isHome ? 'true' : 'false'}&homeRecipe=${homeRecipe ? 'true' : 'false'}`,
        });
    }

    function renderContent() {
        if (props.recipes === null) {
            // return <CircularProgress />
            return (
                <Box className={classes.loading}>
                    <CircularProgress />
                </Box>
            );
        }

        if (!props.recipes.length) {
            return (
                <Typography className={classes.emptyText} variant="body1" align="center">
                    {props.emptyText}
                </Typography>
            );
        }

        return (
            <TransitionGroup component={null}>
                {props.recipes.map((recipe) => (
                    <CSSTransition
                        key={recipe.recipeId}
                        timeout={500}
                        classNames={{
                            enter: 'bounceIn faster',
                            enterActive: 'bounceIn faster',
                            exit: 'bounceOut faster',
                            exitActive: 'bounceOut faster',
                        }}
                    >
                        <GridListTile component="div" className={clsx(classes.gridTile, 'animated')} onClick={() => openRecipe(recipe[idProp], !!recipe.sharedFrom)} key={recipe[idProp]}>
                            {!props.isMeals && recipe.sharedFrom && <UserAvatar className={classes.sharedFrom} user={getFriend(recipe.sharedFrom)} />}
                            {recipe.img
                                ? <img src={getRecipePhoto(recipe.img)} alt={recipe.name} />
                                : <ImagePlaceholder />}
                            <GridListTileBar
                                classes={{
                                    titleWrap: classes.titleWrap,
                                    title: classes.title,
                                    actionIcon: classes.actionButton,
                                    subtitle: classes.subtitle,
                                }}
                                title={recipe.name}
                                subtitle={<span>{recipe.labels && recipe.labels.join(', ')}</span>}
                                actionIcon={props.showOptions ? (
                                    <IconButton
                                        className={classes.recipeOptionsButton}
                                        aria-label="more"
                                        onClick={(event) => onRecipeOptionsClicked(event, recipe)}
                                    >
                                        <MoreIcon color="inherit" fontSize="default" />
                                    </IconButton>
                                ) : null}
                            />
                        </GridListTile>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        );
    }

    return (
        <Box className={classes.grid}>
            { renderContent() }
            {props.showOptions && (
                <>
                    <Menu
                        getContentAnchorEl={null}
                        anchorEl={optionsAnchorEl}
                        open={Boolean(optionsAnchorEl)}
                        onClose={() => setOptionsAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {props.onDelete && (
                            <MenuItem onClick={openDeleteDialog}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="default" />
                                </ListItemIcon>
                                Delete
                            </MenuItem>
                        )}
                        {!props.isMeals && (
                            <MenuItem onClick={openAddMealDialog}>
                                <ListItemIcon>
                                    <AddIcon fontSize="default" />
                                </ListItemIcon>
                                Add to Meal Plan
                            </MenuItem>
                        )}
                    </Menu>
                    <ConfirmDeleteDialog
                        open={showDeleteDialog}
                        handleClose={() => setShowDeleteDialog(false)}
                        item={selectedRecipe}
                        onDelete={props.onDelete}
                        idProp={idProp}
                        itemType={props.isMeals ? 'Meal' : 'Recipe'}
                    />
                    <AddMealDialog
                        open={showAddMealDialog}
                        handleClose={() => setShowAddMealDialog(false)}
                        recipe={selectedRecipe}
                        addMeal={addMealToPlan}
                    />
                </>
            )}
        </Box>
    );
}
