import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

NewRecipeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addRecipe: PropTypes.func.isRequired,
};

export default function NewRecipeDialog(props) {
    const [recipeName, setRecipeName] = useState('');

    return (
        <Dialog open={props.open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New Recipe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the name of the new recipe.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Recipe Name"
                    fullWidth
                    value={recipeName}
                    onChange={(event) => setRecipeName(event.target.value)}
                    error={!recipeName}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()}>
                    Cancel
                </Button>
                <Button disabled={!recipeName} onClick={() => props.addRecipe({ name: recipeName })} variant="contained" color="primary">
                    Add Recipe
                </Button>
            </DialogActions>
        </Dialog>
    );
}
