import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Box, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import LeftArrowIcon from '@material-ui/icons/ArrowLeft';
import RightArrowIcon from '@material-ui/icons/ArrowRight';
import { DatePicker } from '@material-ui/pickers';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import * as queryString from 'query-string';
import { useRecipeManager } from '../../context-providers/recipe-manager-provider';
import IngredientList from '../ingredient/ingredient-list';
import TitleBar from '../nav/title-bar';
import { useMealPlanner } from '../../context-providers/meal-planner-provider';
import IntentButton, { Intent } from '../input/intent-button';
import RecipeField from '../recipe/recipe-field';
import ROUTES from '../../routes/routes';
import RecipeMethod from '../recipe/recipe-method';
import { DEFAULT_FADE_ANIMATION } from '../../constants';
import ConfirmDeleteDialog from '../dialog/confirm-delete-dialog';
import { useFriendships } from '../../context-providers/friendships-provider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttons: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        position: 'sticky',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            top: '54px',
        },
        [theme.breakpoints.up('sm')]: {
            top: '64px',
        },
    },
    arrowButton: {
        padding: '0 12px',
    },
    button: {
        margin: '5px 0',
    },
    method: {
        marginTop: '4px',
        padding: '4px',
        whiteSpace: 'pre-wrap',
        width: '100%',
        minHeight: '30px',
    },
    datePicker: {
        marginLeft: '10px',
        width: '120px',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px 0',
    },
}));

export default function Meal() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { getUserMeal, getHomeMeal, saveUserMeal, saveHomeMeal, removeUserMeal, removeHomeMeal } = useMealPlanner();
    const { mealId } = useParams();
    const { friends } = useFriendships();
    const { getHomeRecipe, getUserRecipe } = useRecipeManager();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const isHome = queryString.parse(location.search).isHome === 'true';

    const getMeal = isHome ? getHomeMeal : getUserMeal;
    const saveMeal = isHome ? saveHomeMeal : saveUserMeal;
    const removeMeal = isHome ? removeHomeMeal : removeUserMeal;

    const meal = getMeal(mealId);

    if (!meal) {
        history.push(ROUTES.PLANNER);
        return <div />;
    }
    const ingredientMultiplier = meal.serves / meal.originalServes;

    function handleMoreServings() {
        saveMeal({ ...meal, serves: meal.serves + 1 });
    }

    function handleLessServings() {
        if (meal.serves > 1) {
            saveMeal({ ...meal, serves: meal.serves - 1 });
        }
    }

    function handleRemove() {
        removeMeal(mealId);
        history.push(ROUTES.PLANNER);
    }

    function findOriginalRecipeLocation() {
        if (getUserRecipe(meal.recipeId)) {
            return {
                pathname: `${ROUTES.RECIPES}/${meal.recipeId}`,
            };
        }
        if (getHomeRecipe(meal.recipeId)) {
            return {
                pathname: `${ROUTES.RECIPES}/${meal.recipeId}`,
                search: 'isHome=true&homeRecipe=true',
            };
        }
        if (meal.sharedFrom) {
            if (friends.find((f) => f.username === meal.sharedFrom)) {
                return {
                    pathname: `${ROUTES.FRIENDS}/${meal.sharedFrom}/${meal.recipeId}`,
                };
            }
        }
    }

    const originalRecipeLocation = findOriginalRecipeLocation();

    return (
        <Box className={classes.root}>
            <TitleBar title={meal.name} onBack={() => history.push(ROUTES.PLANNER)} />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box className={classes.root}>
                    <Box className={classes.buttons}>
                        {originalRecipeLocation && (
                            <IntentButton
                                className={classes.button}
                                onClick={() => history.push(originalRecipeLocation)}
                                variant="contained"
                                intent={Intent.PRIMARY}
                                startIcon={<RestaurantIcon />}
                            >
                                Go to Recipe
                            </IntentButton>
                        )}
                        <IntentButton
                            className={classes.button}
                            onClick={() => setShowDeleteDialog(true)}
                            variant="contained"
                            intent={Intent.ERROR}
                            startIcon={<DeleteIcon />}
                        >
                            Delete
                        </IntentButton>
                    </Box>
                    <RecipeField name="Servings" inline>
                        <Box>
                            <IconButton className={classes.arrowButton} disabled={meal.serves <= 1} onClick={handleLessServings}>
                                <LeftArrowIcon />
                            </IconButton>
                            {meal.serves}
                            <IconButton className={classes.arrowButton} onClick={handleMoreServings}>
                                <RightArrowIcon />
                            </IconButton>
                        </Box>
                    </RecipeField>
                    <RecipeField name="Date" inline>
                        <DatePicker
                            autoOk
                            disableToolbar
                            className={classes.datePicker}
                            label=""
                            format="DD MMM YYYY"
                            value={meal.date ? meal.date : null}
                            onChange={(date) => saveMeal({ ...meal, date: date.toDate() })}
                            disablePast
                        />
                    </RecipeField>
                    <RecipeField name="Ingredients">
                        <IngredientList multiplier={ingredientMultiplier} ingredients={meal.ingredients} editMode={false} />
                    </RecipeField>
                    <RecipeField name="Method">
                        <RecipeMethod method={meal.method} />
                    </RecipeField>
                    <RecipeField name="Notes">
                        <br />
                        <Typography className={clsx(classes.notes, { [classes.emptyText]: !meal.notes })} variant="body1" align="center">
                            {meal.notes ? meal.notes : 'No Notes'}
                        </Typography>
                    </RecipeField>
                    <ConfirmDeleteDialog
                        open={showDeleteDialog}
                        handleClose={() => setShowDeleteDialog(false)}
                        item={meal}
                        onDelete={handleRemove}
                        idProp="mealId"
                        itemType="Meal"
                    />
                    <Box id="bottom-shim" style={{ height: '90px' }} />
                </Box>
            </Fade>
        </Box>

    );
}
