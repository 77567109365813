import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import TickIcon from '@material-ui/icons/CheckCircle';
import IntentButton, { Intent } from '../input/intent-button';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        margin: '10px',
    },
    buttonRow: {
        display: 'flex',
        margin: '10px auto',
    },
    loading: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

HomeInvitation.propTypes = {
    invite: PropTypes.shape({
        id: PropTypes.string,
        sender: PropTypes.shape({ username: PropTypes.string }),
        homeId: PropTypes.string,
    }).isRequired,
    acceptInvite: PropTypes.func.isRequired,
    rejectInvite: PropTypes.func.isRequired,
};

export default function HomeInvitation(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const friend = props.invite.sender;
    if (!friend) return null;

    if (isLoading) {
        return <Box className={classes.loading}><CircularProgress /></Box>;
    }

    return (
        <Paper className={classes.root} elevation={3}>
            <Typography variant="body1" align="center">
                {`Your friend ${friend.username} has invited you to join their home. From there you will have a shared planner and shopping
                 lists (Don't worry you can still access your own).`}
            </Typography>
            <Box className={classes.buttonRow}>
                <IntentButton
                    onClick={() => {
                        setIsLoading(true);
                        props.rejectInvite(props.invite).catch(() => setIsLoading(false));
                    }}
                    variant="contained"
                    intent={Intent.ERROR}
                    startIcon={<CancelIcon />}
                >
                    Reject
                </IntentButton>
                <IntentButton
                    onClick={() => {
                        setIsLoading(true);
                        props.acceptInvite(props.invite).catch(() => setIsLoading(false));
                    }}
                    variant="contained"
                    intent={Intent.SUCCESS}
                    startIcon={<TickIcon />}
                >
                    Accept
                </IntentButton>
            </Box>
        </Paper>
    );
}
