import React from 'react';
import Box from '@material-ui/core/Box';
import { Fade } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import TitleBar from '../components/nav/title-bar';
import { DEFAULT_FADE_ANIMATION } from '../constants';
import { useSettings } from '../context-providers/settings-provider';
import ImageUploader from '../components/input/image-uploader';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    userImg: {
        margin: '10px',
        width: '80px',
        height: '80px',
    },
}));

export default function Settings() {
    const { publicSettings, updateSettings: updatePublicSettings } = useSettings();
    const classes = useStyles();

    function onPropChange(propName, value) {
        updatePublicSettings({
            ...publicSettings,
            [propName]: value,
        });
    }

    return (
        <Box>
            <TitleBar title={publicSettings.username} />
            <Fade in timeout={DEFAULT_FADE_ANIMATION}>
                <Box className={classes.root}>
                    <Avatar alt={publicSettings.username.toLocaleUpperCase()} src={publicSettings.img || '#'} className={classes.userImg} />
                    <ImageUploader
                        className={classes.photoButton}
                        buttonText={`${publicSettings.img ? 'Edit' : 'Add'} photo`}
                        onChange={(img) => onPropChange('img', img)}
                    />
                </Box>
            </Fade>
        </Box>
    );
}
