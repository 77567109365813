import React, { createContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import * as PropTypes from 'prop-types';
import { useDataStore } from './datastore-provider';
import { useHome } from './home-provider';
import { useSnackbar } from 'material-ui-snackbar-provider';

const COLLECTION_NAME = 'shoppingLists';
const ShoppingListStore = createContext({});
export const useShoppingListStore = () => React.useContext(ShoppingListStore);

const SHOPPING_LIST_DEFAULTS = {
    items: [],
};

ShoppingListStoreProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
export function ShoppingListStoreProvider(props) {
    const [userShoppingLists, setUserShoppingLists] = useState(null);
    const [homeShoppingLists, setHomeShoppingLists] = useState(null);
    const { userDb } = useDataStore();
    const { homeDb } = useHome();
    const snackbar = useSnackbar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchShoppingLists(userDb, setUserShoppingLists), [userDb]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchShoppingLists(homeDb, setHomeShoppingLists), [homeDb]);

    function fetchShoppingLists(db, setter) {
        if (db) {
            db.collection(COLLECTION_NAME)
                .onSnapshot((querySnapshot) => {
                    setter(querySnapshot.docs.map((doc) => loadShoppingList(doc.data(), doc.id))
                        .sort(compareLists));
                }, (error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        } else {
            setter(null);
        }
    }

    function compareLists(a, b) {
        if (!a.created || !b.created) return 0;
        return a.created.seconds > b.created.seconds ? -1 : 1;
    }

    function loadShoppingList(list, id) {
        return {
            ...list,
            shoppingListId: id,
            items: list.items || [],
        };
    }

    function addUserList(list) { return addShoppingList(list, userShoppingLists, userDb); }
    function addHomeList(list) { return addShoppingList(list, homeShoppingLists, homeDb); }
    function addShoppingList(shoppingList, theLists, db) {
        return new Promise((resolve, reject) => {
            const newList = { ...cloneDeep(SHOPPING_LIST_DEFAULTS), ...shoppingList, created: new Date() };
            if (db) {
                db.collection(COLLECTION_NAME).add(newList)
                    .then((docRef) => {
                        resolve(docRef.id);
                        snackbar.showMessage('List Added', null, null, { severity: 'success' });
                    })
                    .catch((error) => {
                        console.debug(error);
                        snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                        reject(error);
                    });
            }
        });
    }

    function saveUserList(list, notifySuccess) { saveShoppingList(list, userShoppingLists, userDb, notifySuccess); }
    function saveHomeList(list, notifySuccess) { saveShoppingList(list, homeShoppingLists, homeDb, notifySuccess); }
    function saveShoppingList(shoppingList, theLists, db, notifySuccess = true) {
        if (db) {
            db.collection(COLLECTION_NAME).doc(shoppingList.shoppingListId).update(shoppingList)
                .then(() => notifySuccess && snackbar.showMessage('List Saved', null, null, { severity: 'success' }))
                .catch((error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        }
    }

    function removeUserList(list) { removeShoppingList(list, userShoppingLists, userDb); }
    function removeHomeList(list) { removeShoppingList(list, homeShoppingLists, homeDb); }
    function removeShoppingList(listId, theLists, db) {
        if (db) {
            const undoRemove = () => {
                const deleted = theLists.find((sl) => sl.shoppingListId === listId);
                db.collection(COLLECTION_NAME).doc(listId)
                    .set(deleted)
                    .then(() => snackbar.showMessage('Action Undone', null, null, { severity: 'success' }));
            };
            db.collection(COLLECTION_NAME).doc(listId).delete()
                .then(() => snackbar.showMessage('List Deleted', 'UNDO', undoRemove, { severity: 'success' }))
                .catch((error) => {
                    console.debug(error);
                    snackbar.showMessage('Oops! Something went wrong', null, null, { severity: 'error' });
                });
        }
    }

    function getUserList(listId) { return getShoppingList(listId, userShoppingLists); }
    function getHomeList(listId) { return getShoppingList(listId, homeShoppingLists); }
    function getShoppingList(listId, theLists) {
        if (!listId || !theLists) return null;
        const shoppingList = theLists.find((sl) => sl.shoppingListId === listId);
        return cloneDeep(shoppingList);
    }

    return (
        <ShoppingListStore.Provider value={{
            userShoppingLists,
            homeShoppingLists,
            addUserList,
            addHomeList,
            saveUserList,
            saveHomeList,
            removeUserList,
            removeHomeList,
            getUserList,
            getHomeList,
        }}
        >
            { props.children }
        </ShoppingListStore.Provider>
    );
}
