import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import { ListItemText } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    member: {
        marginLeft: '10px',
    },
}));

HomeMember.propTypes = {
    member: PropTypes.shape({
        username: PropTypes.string,
        img: PropTypes.string,
        role: PropTypes.shape({ name: PropTypes.string }),
    }).isRequired,
    onMemberClicked: PropTypes.func,
};

HomeMember.defaultProps = {
    onMemberClicked: null,
};

export default function HomeMember(props) {
    const classes = useStyles();
    return (
        <ListItem>
            <Avatar alt={props.member.username.toLocaleUpperCase()} src={props.member.img || '#'} />
            <ListItemText className={classes.member}>
                <span>{props.member.username}</span>&nbsp;
                <span className={classes.roleName}>({props.member.role.name})</span>
            </ListItemText>
            { props.onMemberClicked && (
                <ListItemSecondaryAction>
                    <IconButton
                        className={classes.reject}
                        onClick={(e) => props.onMemberClicked(e, props.member)}
                        aria-label="reject-invite"
                    >
                        <MoreIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}
