import cloneDeep from 'lodash.clonedeep';
import pluralize from 'pluralize';
import * as math from 'mathjs';
import * as uuid from 'uuid';
import { SINGULAR_UNITS } from '../constants';
import formatIngredient, { parseAmount } from './ingredient-helper';

export default function generateShoppingList(meals) {
    const ingredientMap = {};

    meals.forEach((meal) => {
        meal.ingredients.forEach((i) => {
            const ingredient = cloneDeep(i);
            ingredient.amount = parseAmount(ingredient.amount);
            const ingredientId = createIngredientId(ingredient);
            if (ingredientMap[ingredientId]) {
                if (ingredient.amount) {
                    ingredientMap[ingredientId].amount = math.add(ingredientMap[ingredientId].amount, ingredient.amount);
                }
            } else {
                ingredientMap[ingredientId] = ingredient;
            }
        });
    });
    // return { ingredients: Object.keys(ingredientMap).sort().map((key) => fixPluralisation(ingredientMap[key])) };
    return { items: Object.keys(ingredientMap).sort().map((key) => (
        {
            id: uuid(),
            text: formatIngredient(fixPluralisation(ingredientMap[key]), 1, true),
        }
    )) };
}

function createIngredientId(ingredient) {
    // Use the plural version so it will match if we have a mix of singular and plural
    const parts = ingredient.name.trim().toLocaleLowerCase().split(' ');
    parts[parts.length - 1] = pluralize(parts[parts.length - 1]);
    return `${parts.join(' ')}-${ingredient.unit ? ingredient.unit.toLocaleLowerCase() : 'none'}`;
}

function fixPluralisation(ingredient) {
    if (ingredient.amount) {
        if (ingredient.unit) {
            if (!SINGULAR_UNITS.includes(ingredient.unit)) {
                return {
                    ...ingredient,
                    unit: pluralize(ingredient.unit, math.number(ingredient.amount)),
                };
            }
        } else {
            const parts = ingredient.name.trim().toLocaleLowerCase().split(' ');
            parts[parts.length - 1] = pluralize(parts[parts.length - 1], math.number(ingredient.amount));
            return {
                ...ingredient,
                name: parts.join(' '),
            };
        }
    }
    return ingredient;
}
