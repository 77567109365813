import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import cloneDeep from 'lodash.clonedeep';
import Badge from '@material-ui/core/Badge';
import { getMealsForDay } from '../../util/meal-helper';
import { useMealPlanner } from '../../context-providers/meal-planner-provider';
import { useHome } from '../../context-providers/home-provider';

AddMealDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    recipe: PropTypes.shape({}),
    addMeal: PropTypes.func.isRequired,
};

AddMealDialog.defaultProps = {
    recipe: null,
};
export default function AddMealDialog(props) {
    const { recipe, open, handleClose, addMeal } = props;
    const initialMeal = cloneDeep({ ...recipe, date: new Date() });
    const [meal, setMeal] = useState();
    const { userMeals, homeMeals } = useMealPlanner();
    const { home } = useHome();

    const isHome = home && !!home.id;
    const meals = isHome ? homeMeals : userMeals;

    useEffect(() => {
        setMeal(initialMeal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.recipe]);

    if (!meal) {
        return <div />;
    }

    function isMealValid() {
        return meal.serves && meal.serves > 0 && meal.date;
    }

    return (
        <Dialog open={open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add to Meal Plan</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choose the serving size and date you will be making this meal (You can change this later)
                </DialogContentText>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        label="Serves"
                        type="number"
                        value={meal.serves}
                        onChange={(event) => setMeal({ ...meal, serves: parseInt(event.target.value, 10) })}
                        error={!meal.serves || meal.serves <= 0}
                        inputProps={{ min: '1', step: '1' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <DatePicker
                        autoOk
                        disableToolbar
                        margin="normal"
                        label="Date"
                        format="DD MMM YYYY"
                        value={meal.date ? meal.date : null}
                        onChange={(date) => setMeal({ ...meal, date: date.toDate() })}
                        disablePast
                        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                            const mealsOnDay = getMealsForDay(meals, day);
                            return <Badge color="secondary" badgeContent={mealsOnDay.length ? mealsOnDay.length : undefined}>{dayComponent}</Badge>;
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>
                    Cancel
                </Button>
                <Button disabled={!isMealValid()} onClick={() => addMeal(meal)} variant="contained" color="primary">
                    Add Meal
                </Button>
            </DialogActions>
        </Dialog>
    );
}
