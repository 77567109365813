import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { CircularProgress, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useFriendships } from '../../context-providers/friendships-provider';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    loading: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyText: {
        color: theme.palette.grey.A200,
        padding: '10px',
    },
    userName: {
        marginLeft: '10px',
    },
}));

export default function SentInvitationList() {
    const { sentInvitations } = useFriendships();
    const classes = useStyles();

    if (!sentInvitations) {
        return (
            <Box className={classes.loading}>
                <CircularProgress />
            </Box>
        );
    }


    if (!sentInvitations) {
        return <Box className={classes.loading}><CircularProgress /></Box>;
    }

    if (!sentInvitations.length) {
        return (
            <Typography className={classes.emptyText} variant="body1" align="center">
                You have no pending friend requests
            </Typography>
        );
    }

    return (
        <>
            <List aria-label="invitations">
                {sentInvitations.map((invite) => (
                    <ListItem key={invite.id}>
                        <Avatar alt={invite.username.toLocaleUpperCase()} src={invite.img || '#'} />
                        <ListItemText className={classes.userName}>
                            {invite.username}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </>
    );
}
