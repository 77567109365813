const ROUTES = {
    HOME: '/home',
    LOGIN: '/login',
    RECIPES: '/recipes',
    PLANNER: '/planner',
    SHOPPING: '/shopping',
    SIGNUP: '/signup',
    CONFIRM: '/confirm',
    SETUP: '/setup',
    FRIENDS: '/friends',
    SETTINGS: '/settings',
};

export default ROUTES;
